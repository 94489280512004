import { Component, OnInit } from '@angular/core';
import { AuthenticationGenSvc } from '../../../services_autogenerated/generated_services';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  username: string;
  loading = false;
  success = false;
  responseMessage: string;

  constructor(
    public authenticationSerivce: AuthenticationGenSvc
  ) { }

  ngOnInit() {
  }

  submit() {
    this.loading = true;
    this.authenticationSerivce.resetPassword(this.username)
      .subscribe(result => {
        this.loading = false;
        this.success = true;
        this.responseMessage = 'Password request sent. Please check your inbox for instructions to set/reset your password.';
      }, this.catchResetError);
  }

  catchResetError = (error: any) => {
    this.loading = false;
    console.log(error);
    if (error.status == 409) {
      this.responseMessage = 'Username not found.';
    } else {
      this.responseMessage = 'Error processing request.';
    }
  }
}
