<br>
<div>
  <span class="headerText">Pesticide Application Record</span>
  <div style="float:right">
    <div class="addNewButton">
      <button pButton type="button" icon="pi pi-refresh" (click)="refreshPage()"
        class="ui-button-warning blackBorder marginCalendar">
      </button>
      <div class="marginCalendar">
        <p-calendar name="filterDates" [(ngModel)]="filterDates" selectionMode="range" class="secondary"
          (ngModelChange)="onFilterDateChange()" placeholder="Filter by Date Range">
        </p-calendar>
      </div>
      <button pButton type="button" label="Add New" (click)="onAddNew()" class="ui-button-success blackBorder"></button>
    </div>
  </div>
</div>

<p-table class="lessTablePad" [columns]="cols" [value]="records" [paginator]="true" [rows]="30" [responsive]="true">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width" class="ui-p-{{col.uiPriority}}">
        {{col.header}}
        <p-sortIcon [field]="col.field" *ngIf="col.header != ''" ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
        </p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-record let-columns="columns" let-rowIndex="rowIndex">
    <tr>
      <td class="ui-p-{{ columns[0].uiPriority }}">{{record.dateApplied | date}}</td>
      <td class="ui-p-{{ columns[1].uiPriority }}">{{record.applicator.fullName}}</td>
      <td class="ui-p-{{ columns[2].uiPriority }}">{{record.certifiedApplicator.fullName}}</td>
      <td class="ui-p-{{ columns[3].uiPriority }}">{{record.chemical.displayName}}</td>
      <td class="ui-p-{{ columns[4].uiPriority }}">{{record.chemical.registrationNumber}}</td>
      <td class="ui-p-{{ columns[5].uiPriority }}">{{record.chemical.mixRate}}</td>
      <td class="ui-p-{{ columns[6].uiPriority }}">{{record.amountConcentrateUsed}}</td>
      <td class="ui-p-{{ columns[7].uiPriority }}">{{record.totalVolume}}</td>
      <td class="ui-p-{{ columns[8].uiPriority }}">{{record.chemicalApplicationMethod.method}}</td>
      <td class="ui-p-{{ columns[9].uiPriority }}">{{record.startTime | date:'shortTime':'-0400'}}</td>
      <td class="ui-p-{{ columns[10].uiPriority }}">{{record.endTime | date:'shortTime':'-0400'}}</td>
      <td class="ui-p-{{ columns[11].uiPriority }}">{{record.windDirectionAndSpeed}}</td>
      <td class="ui-p-{{ columns[12].uiPriority }}">{{record.temperature}}</td>
      <td class="ui-p-{{ columns[13].uiPriority }}">{{record.chemicalApplicationLocation.location}}</td>
      <td class="ui-p-{{ columns[14].uiPriority }}">{{customCustomerService.getCustomerFullName(record.wellnessWorkOrder.customer)}}</td>
      <td class="ui-p-{{ columns[15].uiPriority }}">{{record.wellnessWorkOrder.address.street}}</td>
      <td class="ui-p-{{ columns[16].uiPriority }}">
        <button pButton [disabled]="!canEditAndDelete" type="button" icon="pi pi-pencil" (click)="editRecord(record)"
          class="ui-button-secondary blackBorder editButtonMargin">
        </button>
        <button pButton [disabled]="!canEditAndDelete" type="button" icon="pi pi-trash" (click)="deleteRecord(record)"
          class="ui-button-danger tableButtonMarginLeft">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
