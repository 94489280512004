import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DecodedJwt } from '../models/DecodedJwt';
import * as jwt_decode from 'jwt-decode';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class AuthHelperService {
    isLoggedInSubject = new Subject<boolean>();
    readonly authToken = 'russell_tree_auth_token';

    private urlSuffix = 'api/v1/authentication'; // URL to web api
    private url: string;
    constructor(
        private http: HttpClient,
        private router: Router,
        protected localStorage: LocalStorage,
        private messageService: MessageService,
        private route: ActivatedRoute
    ) { }

    logOut() {
        localStorage.removeItem(this.authToken);
        this.router.navigate(['/login']);
        this.isLoggedInSubject.next(false);
    }

    getDecodedAccessToken(token?: string): DecodedJwt {
        try {
            if (!token) { token = localStorage.getItem(this.authToken); }
            const decodedToken = jwt_decode(token);
            return new DecodedJwt(decodedToken);
        } catch (Error) {
            return null;
        }
    }

    isLoggedIn(): boolean {
        const token = this.getDecodedAccessToken();
        let notExpired = token && new Date(token.exp * 1000) > new Date(); // change to ms then date
        if (!notExpired) {  // makes notExpired non null
            notExpired = false;
        }
        this.isLoggedInSubject.next(notExpired);
        return notExpired;
    }

    redirectToHome() {
        const token = this.getDecodedAccessToken();
        if (this.requiresPasswordReset(token)) {
            if (this.route.snapshot.queryParams.asAnonymous) {
                const extras: NavigationExtras = {
                    queryParams: { 'asAnonymous': this.route.snapshot.queryParams.asAnonymous }
                  };
                  this.router.navigate(['/passwordChange'], extras);
            } else {
                this.router.navigate(['/passwordChange']);
            }
        } else if (token.userType === 'customer') {
            this.router.navigate(['/customerLandingPage/' + token.id]);
        } else if (token.roles.includes('Arborist') || token.roles.includes('Foreman')) {
            this.router.navigate(['/scheduling/myschedule']);
        } else {
            this.router.navigate(['/welcome']);
        }
        this.isLoggedInSubject.next(true);
    }

    requiresPasswordReset(token: DecodedJwt) {
        if (token.requirePasswordReset === 'True') {
            return true;
        } else {
            return false;
        }
    }

    hasClaim(claim: string) {
        const token = this.getDecodedAccessToken();
        if (Object.keys(token).indexOf(claim) >= 0) {
            return true;
        } else {
            return false;
        }
    }
}
