import { ClaimType } from './claimType';
import { RoleType } from './roleType';

export class DecodedJwt {
    constructor(JSON_JWT) {
        // Pull in everything
        Object.assign(this, ...JSON_JWT);

        // Match up things that don't match
        this.email = JSON_JWT.sub;
        this.firstName = JSON_JWT.given_name;
        this.fullName = JSON_JWT.name;
        this.employeeId = parseInt(JSON_JWT.id, 10);
    }
    email: string;
    firstName: string;
    fullName: string;
    employeeId: number;
    claimNames: ClaimType[];
    claimIds: string[];
    requirePasswordReset: string; //True / False
    // userType is either employee or customer
    userType: string;
    id: number;
    roles: RoleType[];
    exp: number;
}
