<form #PasswordChangeForm="ngForm" autocomplete="off" (ngSubmit)="submit(PasswordChangeForm)">
    <div class="m-auto">
        <div class="p-grid">
            <div class="p-col-12" *ngIf="!wasAnon">
                <h1 class="title">Change Password</h1>
            </div>
            <div class="p-col-12" *ngIf="wasAnon">
                <h1 class="title">Welcome</h1>
                <div>
                    Welcome to the Russell Tree Experts Customer Online Portal! You can log in to review quotes, view schedule dates of upcoming work, view past work, update credit/debit card information, download receipts, and more! First, let’s start by creating a new password below!
                </div>
            </div>
            <!-- <div class="p-col-12">
                <label for="username">Reset Code</label>
                <input class="form-control" name="username" type="text" [(ngModel)]="previousPassword" pInputText
                    required>
            </div> -->
            <div class="p-col-12">
                <label for="password" class="m-top">New Password</label>
                <input 
                    class="form-control" 
                    name="password" 
                    type="password" 
                    #password="ngModel"
                    [(ngModel)]="newPassword"
                    pInputText 
                    required
                    [ngClass]="{ 'is-invalid': submitted && password.invalid }"
                    minlength="5">
                <div *ngIf="PasswordChangeForm.submitted && password.invalid">
                    <p-message *ngIf="password.errors.required" severity="error" text="Password is required."></p-message>
                    <p-message *ngIf="password.errors.minlength" severity="error" text="Password must be at least 5 characters."></p-message> 
                </div>
            </div>
            <div class="p-col-12">
                <label for="password" class="m-top">New Password Confirmation</label>
                <input 
                    class="form-control" 
                    name="confirmPassword" 
                    type="password" 
                    #confirmPassword="ngModel"
                    [(ngModel)]="newPasswordConfirmed"
                    pInputText 
                    required
                    [ngClass]="{ 'is-invalid': submitted && confirmPassword.invalid }"
                    minlength="5"
                >
                <div *ngIf="PasswordChangeForm.submitted && confirmPassword.invalid">
                    <p-message *ngIf="confirmPassword.errors.required" severity="error" text="Password confirmation is required."></p-message>
                    <p-message *ngIf="!passwordsMatch()" severity="error" text="Password confirmation does not match new password."></p-message> 
                </div>
            </div>
            <div class="p-col-12">
                <hr style="margin: 0;">
            </div>
            <div class="p-col-12">
                <button type="submit" pButton class="ui-button-success" label="Submit" style="width: 100%;" [disabled]="saving"></button>
            </div>
        </div>
    </div>
</form>