import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import * as moment from 'moment';
import {
  QuoteRequestGenSvc,
  EmployeeGenSvc,
  Employee,
  QuoteRequest,
  QuoteRequestScheduleUpdate,
  RegionalManagerScheduleEventGenSvc,
  RegionalManagerScheduleEvent,
  Address,
  QuoteRequestType,
  QuoteRequestCompletionStatus,
  BlobGenSvc,
  IWorkOrder,
} from '../../../services_autogenerated/generated_services';
import { DecodedJwt } from '../../../models/DecodedJwt';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthHelperService } from '../../../services/auth-helper.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { ConfirmationService, MessageService } from 'primeng/api';
import { formatDate } from '@angular/common';
import { QuoteRequestFilterPipe } from '../../../pipes/quote-request-filter.pipe';
import { LocationColorService } from '../../../services/location-color.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { CustomerService } from '../../../services/customer.service';
import { PhoneNumberPipe } from 'src/app/pipes/phone-number.pipe';
import { QuoteRequestService } from 'src/app/services/quote-request.service';
import { DisplayBlob } from 'src/app/models/displayBlob';

@Component({
  selector: 'app-schedule-page',
  templateUrl: './schedule-page.component.html',
  styleUrls: ['./schedule-page.component.css'],
  providers: [QuoteRequestFilterPipe, PhoneNumberPipe]
})
export class SchedulePageComponent implements OnInit {
  calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin];
  token: DecodedJwt;
  employee: Employee;
  events = [];
  options: any;
  // Count is used for double click functionality, since dblClick cannot be used on mobile devices
  count = 0;
  woCount = 0;

  canAddOrEdit: boolean;
  canAddFirmtime: boolean;
  isCustomer: boolean;

  displayNewEventModal: boolean;
  newSelectEvent: any;
  blockToUpdate: RegionalManagerScheduleEvent;

  showAdditionalInfoModal: boolean;
  selectedRFQ: QuoteRequest;
  selectedEvent: any;

  rmScheduleEvents: RegionalManagerScheduleEvent[] = [];
  anytimeQuoteRequests: QuoteRequest[] = [];
  customerIssueWOs: IWorkOrder[] = [];
  searchTerm: string = '';

  constraintOptions = [
    {
      // Cannot schedule in the past, employees can schedule 10 years out for flexibility
      start: new Date(),
      end: moment().add(10, 'years').toDate()
    }
  ];

  businessHours;

  assumeMobile = window.innerWidth < 768;

  scheduledQuoteRequests: QuoteRequest[];
  scheduleDate: moment.Moment;
  quoteId: number;
  quoteBillingModalDisplay = false;

  loadingUnscheduledAnytime: boolean;

  longPressDelay: number = 250;

  trackedEvent: any;

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('external') external: ElementRef;

  constructor(private quoteRequestService: QuoteRequestGenSvc,
    private route: ActivatedRoute,
    private router: Router,
    private authHelperService: AuthHelperService,
    private employeeService: EmployeeGenSvc,
    private rmScheduleEventService: RegionalManagerScheduleEventGenSvc,
    private confirmationService: ConfirmationService,
    private phoneNumberPipe: PhoneNumberPipe,
    private locationService: LocationColorService,
    private messageService: MessageService,
    public customerService: CustomerService,
    public customQuoteRequestService: QuoteRequestService,
    private blobService: BlobGenSvc) { }

  ngOnInit() {
    this.loadingUnscheduledAnytime = true;
    this.token = this.authHelperService.getDecodedAccessToken();
    this.setPermissions();
    // const quoteId = localStorage.getItem('quoteRequestId');
    // this.quoteId = +quoteId;

    this.options = {
      defaultView: 'listDay',
      editable: this.canAddFirmtime,
      droppable: this.canAddFirmtime,
      selectable: this.canAddFirmtime,
      eventOverlap: false,
      selectOverlap: false,
      height: 'parent',
      customButtons: {
        googleExport: {
          text: 'To Google',
          click: () => {
            this.exportToGoogle();
          }
        },
        mapQuestExport: {
          text: 'To MapQuest',
          click: () => {
            this.exportToMapQuest();
          }
        },
        csvExport: {
          text: 'To CSV',
          click: () => {
            this.exportToCSV();
          }
        }
      },
      header: {
        left: 'prev,next today',
        center: 'title',
        // right: this.isCustomer ? '' : 'dayGridMonth,timeGridWeek,timeGridDay,listDay' // TODO delete and uncomment when signed
        right: this.isCustomer ? '' : 'csvExport,mapQuestExport dayGridMonth,timeGridWeek,timeGridDay,listDay'
      },
      plugins: this.calendarPlugins,
      defaultEventMinutes: { minutes: 60 } // defaultEventMinutes takes a DurationInput object
    };

    const id = +this.route.snapshot.paramMap.get('id');

    if (id) {
      this.employeeService.get(id).subscribe(emp => {
        this.employee = emp;
        if (!this.isCustomer) {
          this.getQuotes();
        } else {
          this.setUnavilableDaysForCustomer();
        }
      });
    } else {
      this.employeeService.get(this.token.employeeId).subscribe(emp => {
        this.employee = emp;
        if (!this.isCustomer) {
          this.getQuotes();
        } else {
          this.setUnavilableDaysForCustomer();
        }
      });
    }
  }

  setPermissions() {
    // Give anon users the ability to add a firmtime because they don't want to scare off anons with forcing an account
    if (!this.token) {
      this.canAddFirmtime = true;
      this.isCustomer = true;
    } else {
      const claims = this.token.claimNames;

      this.canAddOrEdit = claims.includes('View/Edit Own RM Schedule') || claims.includes('View/Edit RM Schedule');
      this.canAddFirmtime = this.canAddOrEdit || claims.includes('Schedule Firmtime Event'); // This is mostly for customers
      this.isCustomer = this.token.userType === 'customer';
    }
  }

  eventRender(model) {
    const event = model.event;
    const view = model.view;
    const element = model.el;

    // If it's a background event, we don't want to render any text inside the FC event
    if (event.rendering !== 'background') {
      // overwrite title and time sections so it looks how they want
      // list view has special classes so it needs it's own overwrite section
      if (view.type === 'listDay') {
        if (!event.allDay) {
          element.querySelector('.fc-list-item-time').innerHTML = this.getTimeText(event.extendedProps.timeBackgroundColor,
                                                                                  event.extendedProps.timeTextColor,
                                                                                  moment(event.start), moment(event.end),
                                                                                  false, event.extendedProps.type);
        }

        let addressLink = '';
        if ((navigator.platform.indexOf('iPhone') !== -1)
          || (navigator.platform.indexOf('iPad') !== -1)
          || (navigator.platform.indexOf('iPod') !== -1)) { /* Use apple maps */
          addressLink = `maps://maps.apple.com/maps?q=${event.extendedProps.address}`;
        } else { /* else use Google  */
          addressLink = `https://maps.google.com/maps?q=${event.extendedProps.address}`;
        }
        element.querySelector('.fc-list-item-title').innerHTML = this.getListTitleText(event, element);

        // Only quote requests get these buttons
        if (event.extendedProps.type !== 'Block') {
          const omwButton = `<br/><button
            class="fc-omw-button fc-button fc-button-primary fc-button-custom"
            ${event.extendedProps.disable ? 'disabled' : ' '}
            type="button">On My Way
          </button>`;

          const directionsButton = `<button
              class="fc-directions-button fc-button fc-button-primary fc-button-custom"
              ${event.extendedProps.disable ? 'disabled' : ' '}
              type="submit">Directions (Map)
            </button>`;

          const buildButton = `<button
            class="fc-build-button fc-button fc-button-primary fc-button-custom"
            ${event.extendedProps.disable ? 'disabled' : ' '}
            type="button">Build Quote
          </button>`;

          element.querySelector('.fc-list-item-title').innerHTML += `<div class="fc-button-group hide" style="display:flex; justify-content: space-between">
                                                                      ${omwButton}${directionsButton}${buildButton}
                                                                    </div>`;

          element.querySelector('.fc-omw-button').addEventListener('click', () => {
            if (event.extendedProps.quoteRequestId) {
              const quote = this.scheduledQuoteRequests.find(qr => qr.id === event.extendedProps.quoteRequestId);
              this.customQuoteRequestService.onMyWay(quote);
            }
          });
          element.querySelector('.fc-directions-button').addEventListener('click', () => {
            if (event.extendedProps.quoteRequestId) {
              window.open(addressLink, '_blank');
            }
          });
          element.querySelector('.fc-build-button').addEventListener('click', () => {
            if (event.extendedProps.quoteRequestId) {
              const quote = this.scheduledQuoteRequests.find(qr => qr.id === event.extendedProps.quoteRequestId);
              this.customQuoteRequestService.verifyQuoteRequestAndBuildQuote(quote);
            }
          });

          element.querySelector('.visibilityToggle').addEventListener('click', () => {
            const toggle = element.querySelector('.visibilityToggle');
            if (toggle.className.includes('pi-plus')) {
              toggle.className = 'visibilityToggle pi pi-minus';
            } else {
              toggle.className = 'visibilityToggle pi pi-plus';
            }

            const extraInformation = element.querySelector('.extra-info');

            if (extraInformation.className.includes('hide')) {
              extraInformation.className = 'extra-info show';
            } else {
              extraInformation.className = 'extra-info hide';
            }

            const buttonGroup = element.querySelector('.fc-button-group');
            if (buttonGroup.className.includes('hide')) {
              buttonGroup.className = 'fc-button-group show-flex';
            } else {
              buttonGroup.className = 'fc-button-group hide';
            }
          });
        } else {
          if (element.querySelector('.fc-edit-info')) {
            element.querySelector('.fc-edit-info').addEventListener('click', () => {
              this.trackedEvent = model.event;
              const eventToUpdate: RegionalManagerScheduleEvent = this.rmScheduleEvents
                .find(rmEvent => rmEvent.id === model.event.extendedProps.scheduleEventId);
              this.blockToUpdate = eventToUpdate;
              this.displayNewEventModal = true;
            });
          }
        }

        if (event.extendedProps.isUrgent) {
          element.classList.add('urgent');
        }
      } else {
        if (!event.allDay) {
          element.querySelector('.fc-time').innerHTML = this.getTimeText(event.extendedProps.timeBackgroundColor,
                                                                          event.extendedProps.timeTextColor,
                                                                          moment(event.start), moment(event.end),
                                                                          event.extendedProps.removable, undefined);
        }
        element.querySelector('.fc-title').innerHTML = event.title;

        if (element.querySelector('.fc-more-info')) {
          element.querySelector('.fc-more-info').addEventListener('click', () => {
            if (model.view.type !== 'listDay') {
              this.selectedRFQ = this.scheduledQuoteRequests.find(rfq => rfq.id === model.event.extendedProps.quoteRequestId);
              this.selectedEvent = model.event;
              this.showAdditionalInfoModal = true;
            }
          });
        }

        if (element.querySelector('.fc-edit-info')) {
          element.querySelector('.fc-edit-info').addEventListener('click', () => {
            if (model.view.type !== 'listDay') {
              this.trackedEvent = model.event;
              const eventToUpdate: RegionalManagerScheduleEvent = this.rmScheduleEvents
                .find(rmEvent => rmEvent.id === model.event.extendedProps.scheduleEventId);
              this.blockToUpdate = eventToUpdate;
              this.displayNewEventModal = true;
            }
          });
        }

        if (element.querySelector('.fc-delete-item')) {
          element.querySelector('.fc-delete-item').addEventListener('click', () => {
            if (event.extendedProps.scheduleEventId) {
              if (confirm('Delete this event?')) {
                this.rmScheduleEventService.deleteById(event.extendedProps.scheduleEventId).subscribe(() => {
                  event.remove();
                  const index = this.events.findIndex(e => e.scheduleEventId === event.extendedProps.scheduleEventId);
                  this.events.splice(index, 1);
                });
              }
            } else if (event.extendedProps.quoteRequestId) {
              if (confirm('Unschedule this quote request?')) {
                this.count = 0;
                this.quoteRequestService.unscheduleById(event.extendedProps.quoteRequestId).subscribe(qr => {
                  this.count = 0;
                  event.remove();
                  const index = this.events.findIndex(e => e.quoteRequestId === event.extendedProps.quoteRequestId);
                  this.events.splice(index, 1);
                  this.anytimeQuoteRequests.push(qr); // if they want to be able to unschedule firm-times, do this.getQuotes() instead
                });
              }
            }
          });
        }
      }
    }
  }

  eventReceive(model) {
    const event = model.event;
    const requestUpdate = new QuoteRequestScheduleUpdate({
      regionalManagerId: this.employee.id,
      eventStart: event.start,
      eventEnd: event.end ? event.end : moment(event.start).add(this.options.defaultEventMinutes.minutes, 'minutes').toDate(),
      quoteRequestId: event.extendedProps.quoteRequestId
    });

    this.quoteRequestService.updateStartOrEnd(requestUpdate, requestUpdate.quoteRequestId.toString()).subscribe(qr => {
      // Remove the Quote Request that was just scheduled from the list so it isn't on the schedulable list
      this.anytimeQuoteRequests.splice(this.anytimeQuoteRequests.findIndex(q => q.id === qr.id), 1);
    });
  }

  eventDrop(model) {
    const event = model.event;
    if (event.extendedProps.quoteRequestId) {
      model.event.editable = true;
      const requestUpdate = new QuoteRequestScheduleUpdate({
        regionalManagerId: this.employee.id,
        eventStart: event.start,
        eventEnd: event.end ? event.end : moment(event.start).add(this.options.defaultEventMinutes.minutes, 'minutes').toDate(),
        quoteRequestId: event.extendedProps.quoteRequestId
      });
      this.quoteRequestService.updateStartOrEnd(requestUpdate, requestUpdate.quoteRequestId.toString()).subscribe();
    } else if (event.extendedProps.scheduleEventId) {
      const eventToUpdate: RegionalManagerScheduleEvent = this.rmScheduleEvents
        .find(rmEvent => rmEvent.id === event.extendedProps.scheduleEventId);

      const eventFromEvents = this.events.find(e => e.scheduleEventId === event.extendedProps.scheduleEventId);
      eventFromEvents.start = event.start;
      eventFromEvents.end = event.end ? event.end : moment(event.start).add(this.options.defaultEventMinutes.minutes, 'minutes').toDate();

      eventToUpdate.eventStart = event.start;
      eventToUpdate.eventEnd =
        event.end ? event.end : moment(event.start).add(this.options.defaultEventMinutes.minutes, 'minutes').toDate();

      this.rmScheduleEventService.update(eventToUpdate, eventToUpdate.id.toString()).subscribe();
    }
  }

  clickEvent(model) {
    let id;
    if (isNaN(model)) {
      id =  model.event.extendedProps.quoteRequestId;
    } else {
      id = model;
    }

    this.count++;
    setTimeout(() => {
      // can only navigate to Quote Request Maintenance if there is an ID and they are not a customer
      if (this.count > 1 && !this.isCustomer && id) {
        this.navigateToQuoteRequest(id);
        this.count = 0;
      }

      this.count = 0;
    }, 250);
  }

  clickWOEvent(wo: IWorkOrder) {
    this.woCount++;
    setTimeout(() => {
      // can only navigate to Quote Request Maintenance if there is an ID and they are not a customer
      if (this.woCount > 1 && !this.isCustomer && wo) {
        if (wo.quoteNumber.endsWith('s')) {
          this.router.navigate(['/wellnessWorkOrderMaintenance/' + wo.id]);
        } else if (wo.quoteNumber.endsWith('k')) {
          this.router.navigate(['/workWorkOrderMaintenance/' + wo.id]);
        }
        this.woCount = 0;
      }

      this.woCount = 0;
    }, 250);
  }

  eventResize(model) {
    const event = model.event;

    if (event.extendedProps.quoteRequestId) {
      const requestUpdate = new QuoteRequestScheduleUpdate({
        regionalManagerId: this.employee.id,
        eventStart: event.start,
        eventEnd: event.end ? event.end : moment(event.start).add(this.options.defaultEventMinutes.minutes, 'minutes').toDate(),
        quoteRequestId: event.extendedProps.quoteRequestId
      });

      this.quoteRequestService.updateStartOrEnd(requestUpdate, requestUpdate.quoteRequestId.toString()).subscribe();
    } else if (event.extendedProps.scheduleEventId) {
      const eventToUpdate: RegionalManagerScheduleEvent = this.rmScheduleEvents
        .find(rmEvent => rmEvent.id === event.extendedProps.scheduleEventId);

      const eventFromEvents = this.events.find(e => e.scheduleEventId === event.extendedProps.scheduleEventId);
      eventFromEvents.start = event.start;
      eventFromEvents.end = event.end ? event.end : moment(event.start).add(this.options.defaultEventMinutes.minutes, 'minutes').toDate();

      eventToUpdate.eventStart = event.start;
      eventToUpdate.eventEnd = event.end;

      this.rmScheduleEventService.update(eventToUpdate, eventToUpdate.id.toString()).subscribe();
    }
  }

  private confirmScheduleDate(date: moment.Moment) {
    // const quoteId = localStorage.getItem('quoteRequestId');
    // if (quoteId) {
    //   this.confirmationService.confirm({
    //     message: `Your appointment will be scheduled from ${date.format('hh:mm A')} to
    //               ${date.add(this.options.defaultEventMinutes, 'minutes').format('hh:mm A')} on ${date.format('MM/DD/YYYY')}.\n
    //               Do you want to schedule this time and proceed to payment?`,
    //     header: 'Confirm Appointment',
    //     accept: () => {
    //       this.scheduleDate = date;
    //       this.openPaymentModal(+quoteId);
    //     },
    //     reject: () => {
    //       // do nothing
    //     }
    //   });
    // }
  }

  onPaymentSuccess() {
    if (+localStorage.getItem('quoteRequestId')) {
      const requestUpdate = new QuoteRequestScheduleUpdate({
        regionalManagerId: this.employee.id,
        eventStart: this.scheduleDate.toDate(),
        eventEnd: this.scheduleDate.add(1, 'h').toDate(),
        quoteRequestId: +localStorage.getItem('quoteRequestId')
      });

      this.quoteRequestService.updateStartOrEnd(requestUpdate, requestUpdate.quoteRequestId.toString()).subscribe(qr => {
        this.events = this.events.concat({
          backgroundColor: this.getEventBackgroundColor(qr.quoteRequestType),
          borderColor: qr.isUrgent ? 'red !important' : '',
          borderWidth: qr.isUrgent ? '2px !important' : '',
          borderStyle: qr.isUrgent ? 'solid !important' : '',
          customerId: qr.customerId,
          customerName: qr.customer.fullName,
          description: qr.description,
          disable: false,
          editable: this.isCustomer ? false : true,
          end: qr.eventEnd,
          isUrgent: qr.isUrgent,
          phoneNumber: qr.customer.primaryPhone,
          quoteRequestId: qr.id,
          services: this.customQuoteRequestService.getQuoteRequestServicesRequested(qr),
          removable: qr.quoteRequestType !== QuoteRequestType.Firmtime &&
            qr.completionStatus !== QuoteRequestCompletionStatus.Transformed_to_Quote,
          rendering: this.isCustomer && qr.customer.id === this.token.id ? 'background' : '',
          start: qr.eventStart,
          textColor: 'black',
          timeBackgroundColor: qr.address.geoColor,
          timeTextColor: qr.address.textColor,
          title: this.getATagForRFQNavigation(qr.id, this.customerService.getCustomerFullName(qr.customer)),
          type: qr.quoteRequestType,
          zipCode: qr.address.zip
        });
      });
      localStorage.removeItem('zipCode');
      localStorage.removeItem('quoteRequestId');
    }
  }

  eventSelect = (model) => {
    const duration = moment.duration(moment(model.end).diff(model.start));
    const isAnHour = duration.asHours() === 1;
    if (this.isCustomer && !isAnHour) {
      alert('Appointment must be an hour long');
    } else {
      if (this.isCustomer) {
        // Customer scheduling a firmtime
        // Ignore the end time, the event will only be an hour for the customer appointment
        this.confirmScheduleDate(moment(model.start));
      } else {
        // Employee scheduling a "block" event (for like dentist appts. or other such)
        this.newSelectEvent = model;
        this.displayNewEventModal = true;
      }
    }
  }

  viewRender(model) {
    const view = model.view;

    // Can only export when on a day view
    if (view.type === 'timeGridDay' || view.type === 'listDay') {
      document.getElementsByClassName('fc-csvExport-button')[0].removeAttribute('hidden');
      document.getElementsByClassName('fc-mapQuestExport-button')[0].removeAttribute('hidden');
      // document.getElementsByClassName('fc-googleExport-button')[0].removeAttribute('hidden');
    } else {
      document.getElementsByClassName('fc-csvExport-button')[0].setAttribute('hidden', 'true');
      document.getElementsByClassName('fc-mapQuestExport-button')[0].setAttribute('hidden', 'true');
      // document.getElementsByClassName('fc-googleExport-button')[0].setAttribute('hidden', 'true');
    }
  }

  saveNewEvent(event) {
    // Saves a new "block" event for the employee
    if (event.id) {
      const rMScheduleEvent = new RegionalManagerScheduleEvent({
        title: event.title,
        additionalText: event.additionalText,
        employeeId: this.employee.id,
        eventStart: this.blockToUpdate.eventStart,
        eventEnd: this.blockToUpdate.eventEnd,
        allDay: false,
        id: this.blockToUpdate.id
      });

      this.rmScheduleEventService.update(rMScheduleEvent, event.id).subscribe(rmEvent => {
        // Okay, gross. But. Remove the event from the calendar, then readd the event with
        // the new info. Because the FC angular component doesn't have a clean way
        // that I found to simply update the event when the title changes.
        this.trackedEvent.remove();
        const index = this.events.findIndex(e => e.scheduleEventId === event.scheduleEventId);
        this.events.splice(index, 1);

        const replacementEvent = {
          start: rmEvent.eventStart,
          end: rmEvent.eventEnd,
          backgroundColor: this.getEventBackgroundColor('block'),
          removable: true,
          scheduleEventId: rmEvent.id,
          textColor: 'black',
          title: this.getTitleText(rmEvent.title, rmEvent.additionalText),
          type: 'Block',
          editable: true
        };
        this.calendarComponent.getApi().addEvent(replacementEvent);
        this.events.push(replacementEvent);

        const eventToUpdate: RegionalManagerScheduleEvent = this.rmScheduleEvents
                .find(e => e.id === rmEvent.id);
        eventToUpdate.additionalText = rmEvent.additionalText;
        eventToUpdate.title = rmEvent.title;

        this.blockToUpdate = null;
      });
    } else {
      const rMScheduleEvent = new RegionalManagerScheduleEvent({
        title: event.title,
        additionalText: event.additionalText,
        employeeId: this.employee.id,
        eventStart: this.newSelectEvent.start,
        eventEnd: this.newSelectEvent.end,
        allDay: false
      });
      this.rmScheduleEventService.add(rMScheduleEvent).subscribe(newEvent => {
        this.rmScheduleEvents.push(newEvent);
        this.calendarComponent.getApi().addEvent({...this.newSelectEvent,
          backgroundColor: this.getEventBackgroundColor('block'),
          removable: true,
          scheduleEventId: newEvent.id,
          textColor: 'black',
          title: this.getTitleText(event.title, event.additionalText),
          type: 'Block',
          editable: true
        });
        // Add new block to the calendar
        // this.events = this.events.concat(
        //   {...this.newSelectEvent,
        //     backgroundColor: this.getEventBackgroundColor('block'),
        //     removable: true,
        //     scheduleEventId: newEvent.id,
        //     textColor: 'black',
        //     title: this.getTitleText(event.title, event.additionalText),
        //     type: 'Block',
        //     editable: true
        //   }
        // );
      });
    }

    this.displayNewEventModal = false;
  }

  closeNewEventModal() {
    this.displayNewEventModal = false;
    this.blockToUpdate = null;
  }

  navigateToQuoteRequest(id) {
    this.router.navigateByUrl('requestedQuoteMaintenance/' + id);
    // window.open('requestedQuoteMaintenance/' + id, '_blank');
  }

  // Uses FullCalendar's "business hours" to keep customer scheduling during time slots
  setUnavilableDaysForCustomer() {
    const zip = localStorage.getItem('zipCode');
    const timeSlotsForZipCode = this.employee.regionalManagerTimeSlots.
    filter(timeSlot => timeSlot.regionalManagerTimeSlotZipCodes.some(zc => zc.zipCode.code === zip));

    this.businessHours = timeSlotsForZipCode.map(timeSlot => {
      return {
        daysOfWeek: [timeSlot.dayOfTheWeek],
        startTime: formatDate(timeSlot.timeBegin, 'HH:mm', 'en-US'),
        endTime: formatDate(timeSlot.timeEnd, 'HH:mm', 'en-US'),
      };
    });
  }

  getScheduledEvents(model) {
    if (this.calendarComponent) {
      // clear events when changing views so it doesn't duplicate any events
      this.calendarComponent.getApi().removeAllEvents();
    }
    this.events = [];

    // Id as a parameter means someone is navigating to an RM's schedule
    // Otherwise get the id from the token of the logged in user.
    const id = +this.route.snapshot.paramMap.get('id') ? +this.route.snapshot.paramMap.get('id') : this.token.id;

    if (id) {
      // Get the quote requests that have been scheduled for the employee
      this.quoteRequestService.getAllRequestsInDateRangeForRM(id, model.view.activeStart, model.view.activeEnd)
      .subscribe(scheduledRequests => {
        this.scheduledQuoteRequests = scheduledRequests;
        scheduledRequests.map(qr => {
          const classNames = [];
          if (qr.completionStatus === QuoteRequestCompletionStatus.Transformed_to_Quote) {
            classNames.push('complete');
          }

          this.events = this.events.concat({
            address: qr.address.street + ', ' + qr.address.city + ' ' + (qr.address.state != null ? qr.address.state.abbreviation : '') + ', ' + qr.address.zip,
            backgroundColor: this.getEventBackgroundColor(qr.quoteRequestType),
            borderColor: qr.isUrgent ? 'red !important' : '',
            borderWidth: qr.isUrgent ? '2px !important' : '',
            borderStyle: qr.isUrgent ? 'solid !important' : '',
            classNames: classNames,
            customerId: qr.customerId,
            customerName: qr.customer.fullName,
            description: qr.description,
            disable: qr.completionStatus === QuoteRequestCompletionStatus.Transformed_to_Quote,
            editable: qr.completionStatus !== QuoteRequestCompletionStatus.Transformed_to_Quote, // disable edit on completed qrs
            end: qr.eventEnd,
            isUrgent: qr.isUrgent,
            phoneNumber: qr.customer.primaryPhone,
            quoteRequestId: qr.id,
            services: this.customQuoteRequestService.getQuoteRequestServicesRequested(qr),
            removable: qr.quoteRequestType !== QuoteRequestType.Firmtime &&
              qr.completionStatus !== QuoteRequestCompletionStatus.Transformed_to_Quote, // disable unschedule completed qrs
            rendering: this.isCustomer ? 'background' : '',
            start: qr.eventStart,
            textColor: 'black',
            timeBackgroundColor: qr.address.geoColor,
            timeTextColor: qr.address.textColor,
            title: this.getTitleText(this.getATagForRFQNavigation(qr.id, this.customerService.getCustomerFullName(qr.customer)),
                                    qr.address.zip,
                                    this.customerService.getPrimaryContact(qr.customer).phoneNumber),
            type: qr.quoteRequestType,
            zipCode: qr.address.zip
          });
        });
      });

      // Get the non-quote request events that have been scheduled for the employee, ie the "blocks"
      this.rmScheduleEventService.getAllEventsInDateRangeForRM(id, model.view.activeStart, model.view.activeEnd)
      .subscribe(scheduledEvents => {
        this.rmScheduleEvents = scheduledEvents;
        scheduledEvents.map(event => {
          this.events = this.events.concat({
            backgroundColor: this.getEventBackgroundColor('block'),
            editable: true,
            end: event.eventEnd,
            removable: true,
            rendering: this.isCustomer ? 'background' : '',
            scheduleEventId: event.id,
            start: event.eventStart,
            textColor: 'black',
            title: this.getTitleText(event.title, event.additionalText),
            type: 'Block'
          });
        });
      });
    }
  }

  editable(event) {
    // console.log(event);
  }

  getQuotes() {
    // Anytime quotes that are not scheduled appear on the right for the employee to schedule
    this.quoteRequestService.getSchedulableAnytimeRequestsForRM(this.employee.id).subscribe(quoteRequests => {
      this.loadingUnscheduledAnytime = false;
      this.anytimeQuoteRequests = quoteRequests;
      // this thing makes the anytime quotes draggable to the calendar
      // Also sets some of the required properties for rendering
      const thing = new Draggable(this.external.nativeElement, {
        itemSelector: '.fc-event',
        longPressDelay: this.longPressDelay,
        eventData: (eventEl) => {
          const eventName = this.getATagForRFQNavigation(eventEl.getAttribute('quoteRequestId'), eventEl.getAttribute('customerName'));
          const additionalText = eventEl.getAttribute('zipCode');
          const secondLineText = eventEl.getAttribute('phoneNumber');
          const address = eventEl.getAttribute('address');
          const type = eventEl.getAttribute('type');
          return {
            address: address,
            backgroundColor: this.getEventBackgroundColor(type),
            duration: this.options.defaultEventMinutes,
            isUrgent: eventEl.getAttribute('isUrgent'),
            removable: true,
            quoteRequestId: eventEl.getAttribute('quoteRequestId'),
            textColor: 'black',
            timeBackgroundColor: eventEl.getAttribute('timeBackgroundColor'),
            timeTextColor: eventEl.getAttribute('timeTextColor'),
            title: this.getTitleText(eventName, additionalText, secondLineText),
            type: type,
            id: eventEl.getAttribute('quoteRequestId'),
            editable: true
          };
        }
      });
    }, error => {
      console.log(error);
      this.loadingUnscheduledAnytime = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Could not load unscheduled Quote Requests, please refresh'
      });
    });

    this.rmScheduleEventService.getAllCustomerIssueWorkOrdersForRM(this.employee.id).subscribe(res => {
      this.customerIssueWOs = res;
    });
  }

  private getATagForRFQNavigation(quoteRequestId, customerName): string {
    return `<a href="requestedQuoteMaintenance/${quoteRequestId}">${customerName}</a>`;
  }

  exportToCSV() {
    if (this.scheduledQuoteRequests && this.scheduledQuoteRequests.length > 0) {
      document.getElementsByClassName('fc-csvExport-button')[0].setAttribute('disabled', 'true');
      document.getElementsByClassName('fc-csvExport-button')[0].removeAttribute('hidden');
      document.body.classList.add('busy-cursor');
      const addresses: Address[] = this.scheduledQuoteRequests.map(qr => qr.address);

      this.locationService.optimizeRoute(addresses).subscribe(res => {
        const data = [];

        res.route.locations.forEach(location => {
          data.push({
            street: location.street,
            city: location.adminArea5,
            state: location.adminArea3,
            postalCode: location.postalCode,
            latitude: location.latLng.lat,
            longitude: location.latLng.lng
          });
        });

        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          headers: ['Street', 'City', 'State', 'Postal Code', 'Latitude', 'Longitude']
        };

        const file = new ngxCsv(data, 'Optimized Route', options);

        document.getElementsByClassName('fc-csvExport-button')[0].removeAttribute('disabled');
        document.body.classList.remove('busy-cursor');
      });
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Nothing Scheduled',
        detail: 'There are no quote requests scheduled so no optimized route can be generated. Please schedule work or choose another day.'
      });
    }
  }

  exportToGoogle() {
    if (this.scheduledQuoteRequests && this.scheduledQuoteRequests.length > 0) {
      document.getElementsByClassName('fc-googleExport-button')[0].setAttribute('disabled', 'true');
      document.body.classList.add('busy-cursor');

      const addresses: Address[] = this.scheduledQuoteRequests.map(qr => qr.address).reverse();
      let googleURL = 'https://www.google.com/maps/dir';
      addresses.forEach(address => {
        googleURL += this.locationService.spaceToPlus(`/${address.street},${address.city},${address.state.abbreviation},${address.zip}`);
      });

      document.getElementsByClassName('fc-googleExport-button')[0].removeAttribute('disabled');
        document.body.classList.remove('busy-cursor');
        // Open Google Maps in a new tab
        window.open(googleURL, '_blank');
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Nothing Scheduled',
        detail: 'There are no quote requests scheduled so no map can be generated. Please schedule work or choose another day.'
      });
    }
  }

  exportToMapQuest() {
    if (this.scheduledQuoteRequests && this.scheduledQuoteRequests.length > 0) {
      document.getElementsByClassName('fc-mapQuestExport-button')[0].setAttribute('disabled', 'true');
      document.body.classList.add('busy-cursor');
      const addresses: Address[] = this.scheduledQuoteRequests.map(qr => qr.address);
      let mapQuestURL = 'https://www.mapquest.com/directions?';
      let locationNumber = 0;
      // Get the optimized route
      this.locationService.optimizeRoute(addresses).subscribe(res => {
        res.route.locations.forEach(location => {
          mapQuestURL += `${locationNumber === 0 ? '' : '&'}q${++locationNumber}=${location.street},
            ${this.locationService.spaceToPlus(location.adminArea5)},${location.adminArea3},${location.postalCode}`;
        });

        document.getElementsByClassName('fc-mapQuestExport-button')[0].removeAttribute('disabled');
        document.body.classList.remove('busy-cursor');
        // Open optimized route in a new tab
        window.open(mapQuestURL, '_blank');
      }, () => {
        if (!this.isCustomer) {
          this.messageService.add({
            severity: 'error',
            summary: 'Route Optimization Failed',
            detail: 'Could not get the Optimized Route from the MapQuest provider. Please try again later.'
          });
        }

        document.getElementsByClassName('fc-mapQuestExport-button')[0].removeAttribute('disabled');
        document.body.classList.remove('busy-cursor');
      });
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Nothing Scheduled',
        detail: 'There are no quote requests scheduled so no optimized route can be generated. Please schedule work or choose another day.'
      });
    }
  }

  // Given the parameters, creates the time text that is displayed on the events
  private getTimeText(backgroundColor: string, textColor: string, start: moment.Moment, end: moment.Moment, removable: boolean, type: string): string {
    const diff = end.diff(start, 'h', true);
    let time = `<div style="background-color: ${backgroundColor}; color: ${textColor}">`;
    time += `<span>${start.format('hh:mm')}-${end.format('hh:mm')} (${diff} HR)`;

    if (removable) {
      time += `<span style="float: right;"><span class="fc-delete-item" style="padding-left: 3px;">X</span></span>`;
    }

    time += '</div>';

    if (type) {
      time += `<span">${type}</span>`;
    }

    return time;
  }

  // Given the parameters, creates the title of the event for display
  // If event is a quote: eventName is Customer's name, additionalText is the zip code, and secondLineText is the phone number
  // If event is a blocK: eventName is whatever they named it, additionalText is whatever they enter for that, and
  //                                                              phoneNumber is not used for blocks (hence it is optional)
  private getTitleText(eventName: string, additionalText: string, phoneNumber?: string): string {
    let title = `<div class="fc-title">${eventName}&nbsp;`;

    if (additionalText) {
      title += `<span style="float: right">${additionalText}</span>`;
    }

    if (phoneNumber) {
      title += `<div><a href="tel: ${phoneNumber}">${this.phoneNumberPipe.transform(phoneNumber)}</a><span class="fc-more-info" style="float: right">More...</span></div>`;
    } else {
      title += `<div><span class="fc-edit-info" style="float: right">Edit</span></div>`;
    }

    title += '</div>';

    return title;
  }

  private getListTitleText(event, element): string {
    let displayBlobs: DisplayBlob[];
    if (event.extendedProps.quoteRequestId > 0) {
      this.blobService.getBlobs('quote-request', event.extendedProps.quoteRequestId)
          .subscribe((blobs) => {
              displayBlobs = blobs.map((blob) => new DisplayBlob(blob));

              let runningBlobElement = '';
              if (displayBlobs.length > 0) {
                displayBlobs.forEach(blob => {
                  if (runningBlobElement) {
                    runningBlobElement += `&nbsp;/ <a style="vertical-align: middle;" class="regular-link" href="${blob.SASUri}">${blob.DisplayName}</a>`;
                  } else {
                    // Otherwise just set it to the value with no comma
                    runningBlobElement += `<a style="vertical-align: middle;" class="regular-link" href="${blob.SASUri}">${blob.DisplayName}</a>`;
                  }
                });
                element.querySelector('.uploads').innerHTML = runningBlobElement;
              } else {
                element.querySelector('.uploads').innerHTML = 'No uploads';
              }
          }, (error) => {
              // Some error occurred, let the user know
              console.log('Error loading uploads: ', error);
              this.messageService.add({
                severity: 'error',
                summary: 'Error loading uploads',
                detail: 'Could not load the uploads on a quote request.'
              });
          });
    }

    let title = `<div class="fc-title">`;

    if (event.extendedProps.type !== 'Block') {
      title += `<div><span class="bold">Customer:</span>
                <a class="regular-link" href="/customerLandingPage/${event.extendedProps.customerId}" target="_blank">${event.extendedProps.customerName}</a>`;
      title += `<span style="float: right">${event.extendedProps.zipCode}</span></div>`;
      title += `<div><span class="bold">Worksite Address:</span> ${event.extendedProps.address}
                  <span style="float: right"><i class="visibilityToggle pi pi-plus"></i></span></div>`;
      title += `<span class="extra-info hide"><div><span class="bold">Customer Phone:</span>  <a class="regular-link" href="tel: ${event.extendedProps.phoneNumber}">
                  ${this.phoneNumberPipe.transform(event.extendedProps.phoneNumber)}</a></div>`;
      title += `<div><span class="bold">Services Requested:</span> ${event.extendedProps.services}</div>`;
      title += `<div class="showLineBreaks"><span class="bold">Quote Request Description:</span> ${event.extendedProps.description}</div>`;
      title += `<div><span class="bold">Customer Provided Photos:</span> <span class="uploads">Loading uploads...</span></div><span>`;
    } else {
      return event.title;
    }

    title += '</div>';

    return title;
  }

  // If you are changing the color style, be sure to check the quote-request-item.component.css so it matches
  private getEventBackgroundColor(type: string) {
    if (type === 'Anytime') {
      return '#98c0e8';
    } else if (type === 'Firmtime') {
      return '#a8f8b8';
    } else if (type === 'block') {
      return '#b7b7b7';
    } else if (type === 'Virtual') {
      return '#ffc585';
    }
  }

  // Was used to display a legend for the background colors, currently commented out in case they want it back.
  // public getKeyStyles(type: string) {
  //   return {
  //     'color': this.getEventBackgroundColor(type)
  //   };
  // }

  openPaymentModal(quoteId: number) {
    // console.log('Set Quote Id: ');
    // console.log(quoteId);
    // this.quoteBillingModalDisplay = true;
  }

  closeQuoteBillingModal() {
    console.log('billing saved emit 4');
    this.quoteBillingModalDisplay = false;
  }

  closeAdditionalInfoModal() {
    this.showAdditionalInfoModal = false;
    this.selectedEvent = undefined;
    this.selectedRFQ = undefined;
  }
}
