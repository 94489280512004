import { Component, OnInit } from '@angular/core';

import { MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthHelperService } from './services/auth-helper.service';
import { DecodedJwt } from './models/DecodedJwt';
import { LogGenSvc } from './services_autogenerated/generated_services';
import { ReportHelperService } from './services/report-helper.service';
import { AppConfigService } from './services/app-config.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  items: MenuItem[];
  isLoggedIn = false;
  logoLink: string;
  token: DecodedJwt;
  showRequestAQuote: boolean;
  showSlideMenu: boolean;

  showMenu: boolean = true;

  environment: string;

  constructor(private messageService: MessageService, private router: Router, private authHelperService: AuthHelperService,
      private route: ActivatedRoute, private log: LogGenSvc, private reportHelper: ReportHelperService,
      private appConfigService: AppConfigService) 
  {
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        // :( RTE only wanted the logo to link out when on the quote page
        if (router.url === '/quote' || router.url === 'quote') {
          this.logoLink = appConfigService.appConfig.logoLink
        }
      }
    );
  }

  ngOnInit() {
    if (this.appConfigService.appConfig.environment && this.appConfigService.appConfig.environment != 'Prod') {
      this.environment = this.appConfigService.appConfig.environment.toUpperCase();
    }
    this.route.queryParams
      .subscribe(params => {
        this.showMenu = !params.embedded;
      });
    
    this.authHelperService.isLoggedInSubject.subscribe(x => {
      this.isLoggedIn = x;
      this.updateItems();
    });

    const token = this.authHelperService.getDecodedAccessToken();
    const url = window.location.href;
    const pathContainsAllowedUrl = url.includes('survey') || url.includes('Quote');

    // This if statement has a hidden functionality. The isLoggedIn command causes the isLoggedInSubject to emit its status.
    if (this.authHelperService.isLoggedIn() && token.userType === 'customer' && !pathContainsAllowedUrl) {
      this.router.navigateByUrl('customerLandingPage/' + token.id);
    }
  }

  onLogout() {
    this.authHelperService.logOut();
  }

  customerPage() {
    this.router.navigateByUrl('customerLandingPage/' + this.token.id);
    this.showSlideMenu = false;
  }

  requestAQuote() {
    this.router.navigateByUrl('/requestedQuoteMaintenance');
    this.showSlideMenu = false;
  }

  updateItems() {

    // Id	Name
    // 1	Full Control
    // 2	To-Do List
    // 3	Be able to Schedule
    // 4	Schedulable
    // 5	View Own Schedule Page
    // 6	View Their Own Profile
    // 7	View/Edit Employee Maint.
    // 8	View/Edit Equipment Maint.
    // 9	View/Edit Crew Creation
    // 10	View/Edit Pesticide/Chemicals
    // 11	View/Edit Customer Maint.
    // 12	View/Edit Priority Maint.
    // 13	View/Edit Skills/Cert.
    // 14	View/Edit Equip. Type
    // 15	View/Edit Pesticide Application Record
    // 16	View/Edit Quote
    // 17	View/Edit Work Order
    // 18	Completing a Task
    // 19	View/Edit Customer Maint. (Comment Only)
    // 20	View/Complete Work Order
    // 21	View Work Order
    // 22	View/Edit Task Template Page
    // 23 View/Edit Their Own Profile
    // 24 View/Edit Their Own Work Order
    // 28 View/Edit Work Order (Comment Only)
    // 29 View/Edit Request for Quote

    if (this.isLoggedIn) {
      this.token = this.authHelperService.getDecodedAccessToken();

      const claims = this.token.claimNames;
      const roles = this.token.roles;

      this.items = [
        {
          label: 'Invoicing',
          items: [
            {
              label: 'Partial Invoicing',
              routerLink: '/invoicing',
              visible: claims.some(c => c === 'Invoice partially completed work orders'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Full Invoicing',
              routerLink: '/invoicing/full',
              visible: claims.some(c => c === 'Invoice partially completed work orders'),
              command: () => this.showSlideMenu = false
            }
          ],
          visible: claims.some(c => c === 'Invoice partially completed work orders')
        },
        // {
        //   label: 'Invoicing',
        //       routerLink: '/invoicing',
        //       visible: claims.some(c => c === 'Invoice partially completed work orders'),
        //       command: () => this.showSlideMenu = false
        // },
        {
          label: 'Schedules',
          items: [
            {
              label: 'Tree Wellness',
              routerLink: '/scheduling/wellness',
              visible: claims.some(c => c === 'Be able to Schedule'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Tree Wellness Multi-day',
              routerLink: '/scheduling/multiday/wellness',
              visible: claims.some(c => c === 'Be able to Schedule'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Tree Work',
              routerLink: '/scheduling/work',
              visible: claims.some(c => c === 'Be able to Schedule'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Tree Work Multi-day',
              routerLink: '/scheduling/multiday/work',
              visible: claims.some(c => c === 'Be able to Schedule'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'My Schedule',
              routerLink: '/scheduling/myschedule',
              visible: claims.some(c => c === 'Be able to Schedule' || c === 'Schedulable'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'My RM Schedule',
              routerLink: '/rmSchedulePage/' + this.token.employeeId,
              visible: claims.some(c => c === 'To-Do List') && !roles.includes('Office Manager') && !roles.includes('Office Manager Admin'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Crew Log',
              routerLink: '/crewLog',
              visible: claims.some(c => c === 'See Crew Log'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'RM Schedules',
              routerLink: '/dashboard/office',
              visible: roles.includes('Office Manager') || roles.includes('Admin') || roles.includes('Office Manager Admin')
            }
          ],
          visible: claims.some(c => c === 'Be able to Schedule' || c === 'Schedulable' || c === 'To-Do List' || c === 'See Crew Log')
        },
        {
          label: 'Quotes',
          routerLink: 'quoteList',
          visible: claims.some(c => c === 'View/Edit Request for Quote' ||
                                    c === 'View/Edit Quote'),
          command: () => this.showSlideMenu = false
        },
        {
          label: 'Work Orders',
          routerLink: 'workOrderList',
          visible: claims.some(c => c === 'View/Edit Work Order' ||
                                    c === 'View/Complete Work Order' ||
                                    c === 'View Work Order'),
            command: () => this.showSlideMenu = false
        },
        {
          label: 'Customers',
          routerLink: 'customerList',
          visible: claims.some(c => c === 'View/Edit Customer Maint.' || c === 'View/Edit Customer Maint. (Comment Only)'),
          command: () => this.showSlideMenu = false
        },
        {
          label: 'Settings',
          visible: this.token.userType !== 'customer' && claims.some(c =>
                                                          c === 'View/Edit Crew Creation' ||
                                                          c === 'View/Edit Customer Maint.' ||
                                                          c === 'View/Edit Customer Maint. (Comment Only)' ||
                                                          c === 'View/Edit Employee Maint.' ||
                                                          c === 'View/Edit Equipment Maint.' ||
                                                          c === 'View/Edit Equip. Type' ||
                                                          c === 'View/Edit Pesticide/Chemicals' ||
                                                          c === 'View/Edit Pesticide Application Record' ||
                                                          c === 'View/Edit Priority Maint.' ||
                                                          c === 'View/Edit Skills/Cert.' ||
                                                          c === 'View/Edit Task Template Page' ||
                                                          c === 'Full Control' ||
                                                          c === 'To-Do List' ||
                                                          c === 'Can Edit Zip Code Maintenance'),
          items: [
            {
              label: 'Crews',
              routerLink: 'crewCreation',
              visible: claims.some(c => c === 'View/Edit Crew Creation'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Current Backlogs',
              routerLink: 'currentBacklogMaintenance',
              visible: claims.some(c => c === 'Full Control') || claims.some(c => c === 'View/Edit Current Backlog'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Customer Surveys', items: [
                {
                  label: 'Survey List',
                  routerLink: 'surveyList',
                  command: () => this.showSlideMenu = false
                },
                {
                  label: 'Survey Maintenance',
                  routerLink: 'surveyMaintenance',
                  command: () => this.showSlideMenu = false
                }
              ],
              visible: roles.includes('Office Manager') || roles.includes('Admin') || roles.includes('Office Manager Admin')
            },
            {
              label: 'Employees',
              routerLink: 'employeeList',
              visible: claims.some(c => c === 'View/Edit Employee Maint.'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Equipment',
              routerLink: 'equipmentList',
              visible: claims.some(c => c === 'View/Edit Equipment Maint.'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Equipment Types',
              routerLink: 'equipmentTypeMaintenance',
              visible: claims.some(c => c === 'View/Edit Equip. Type'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Message To Customer Templates',
              routerLink: 'messageTemplates',
              visible: roles.includes('Office Manager') || roles.includes('Admin') || roles.includes('Office Manager Admin'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Pesticide Applications', items: [
                {
                  label: 'A - Chemicals', routerLink: 'chemicalMaintenance',
                  visible: claims.some(c => c === 'View/Edit Pesticide/Chemicals'),
                  command: () => this.showSlideMenu = false
                },
                {
                  label: 'B - Methods', routerLink: 'chemicalMethodMaintenance',
                  visible: claims.some(c => c === 'View/Edit Pesticide/Chemicals'),
                  command: () => this.showSlideMenu = false
                },
                {
                  label: 'C - Locations', routerLink: 'chemicalLocationMaintenance',
                  visible: claims.some(c => c === 'View/Edit Pesticide/Chemicals'),
                  command: () => this.showSlideMenu = false
                },
                {
                  label: 'Pesticide Application Record', routerLink: 'pesticideApplicationRecordList',
                  visible: claims.some(c => c === 'View/Edit Pesticide Application Record'),
                  command: () => this.showSlideMenu = false
                }
              ],
              visible: claims.some(c => c === 'View/Edit Pesticide/Chemicals' || c === 'View/Edit Pesticide Application Record')
            },
            {
              label: 'Priorities',
              routerLink: 'prioritiesMaintenance',
              visible: claims.some(c => c === 'View/Edit Priority Maint.') || claims.some(c => c === 'Full Control'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Skills/Certifications',
              routerLink: 'skillList', visible:
              claims.some(c => c === 'View/Edit Skills/Cert.') || claims.some(c => c === 'Full Control'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'System Settings',
              routerLink: 'systemSettings', visible:
              claims.some(c => c === 'See System Settings'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Task Templates', items: [
                {
                  label: 'Tree Wellness Tasks',
                  routerLink: 'wellnessTaskTemplateList',
                  command: () => this.showSlideMenu = false
                },
                {
                  label: 'Tree Work Tasks',
                  routerLink: 'workTaskTemplateList',
                  command: () => this.showSlideMenu = false
                }
              ],
              visible: claims.some(c => c === 'View/Edit Task Template Page') || claims.some(c => c === 'Full Control')
            },
            {
              label: 'Tree Type Maintenance',
              routerLink: 'treeTypeMaintenance',
              visible: claims.some(c => c === 'Full Control') || claims.some(c => c === 'View/Edit Tree Type Maintenance'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Zip Code Maintenance',
              routerLink: 'admin/zipCodeMaintenance',
              visible: claims.some(c => c === 'Can Edit Zip Code Maintenance') || claims.some(c => c === 'Full Control'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Zip Code Assignments',
              routerLink: 'admin/zipCodeAssignment',
              visible: claims.some(c => c === 'Can Edit Zip Code Maintenance') || claims.some(c => c === 'Full Control'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Zip Code to Day Assignments',
              routerLink: 'assignZipCodeToDay/' + this.token.employeeId,
              visible: !roles.includes('Office Manager') && !roles.includes('Office Manager Admin') && claims.some(c => c === 'To-Do List') || claims.some(c => c === 'Full Control'),
              command: () => this.showSlideMenu = false
            }
          ]
        },
        {
          label: 'Reporting',
          visible: claims.some(c => c === 'See Reports') || claims.some(c => c === 'Full Control') || claims.some(c => c === 'See Commission Report'),
          items: [
            {
              label: 'Dashboard',
              routerLink: '/reporting',
              visible: claims.some(c => c === 'See Reports') || claims.some(c => c === 'Full Control'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Accounts Receivable',
              routerLink: 'reporting/ar',
              visible: claims.some(c => c === 'See Reports') || claims.some(c => c === 'Full Control') || claims.some(c => c === 'See Commission Report'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Commission Report',
              routerLink: 'reporting/commission',
              visible: claims.some(c => c === 'See Reports') || claims.some(c => c === 'Full Control') || claims.some(c => c === 'See Commission Report'),
              command: () => this.showSlideMenu = false
            },
            {
              label: 'Exports',
              routerLink: 'reporting/export',
              visible: claims.some(c => c === 'See Reports') || claims.some(c => c === 'Full Control'),
              command: () => this.showSlideMenu = false
            },
          ],
        },
      ];
    } else {
      this.token = null;
      this.items = [];
    }
  }
}
