import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Base64 } from 'src/app/models/base64';
import { AppConfigService } from 'src/app/services/app-config.service';
import { NeighborPermissionGenSvc, NotificationsGenSvc, QuoteGenSvc, QuoteWorkOrder } from 'src/app/services_autogenerated/generated_services';

@Component({
  selector: 'app-anonymous-customer-quote-page',
  templateUrl: './anonymous-customer-quote-page.component.html',
  styleUrls: ['./anonymous-customer-quote-page.component.css']
})
export class AnonymousCustomerQuotePageComponent implements OnInit {
  quote: QuoteWorkOrder;
  contactPhoneNumber: string;
  isLoading: boolean;

  weeksOutDateCalculation = {
    start: undefined, end: undefined
  };

  constructor(private route: ActivatedRoute,
    private quoteService: QuoteGenSvc,
    private neighborPermissionService: NeighborPermissionGenSvc,
    private emailService: NotificationsGenSvc,
    private messageService: MessageService,
    private configService: AppConfigService) { }

  ngOnInit() {
    this.contactPhoneNumber = this.configService.appConfig.contactPhoneNumber;
    this.isLoading = true;

    const id = +this.route.snapshot.paramMap.get('id');

    if (id) {
      this.quoteService.getAnon(id).subscribe(res => {
        // If someone gets here for a quote that doesn't need neighbor's permission, just show the blank page
        // saying the quote couldn't be found.
        if (res.neighborPermissionRequired) {
          this.quote = res;
          if (res.priorityTypeShortcut && res.priorityTypeShortcut.weeksOutStart !== undefined) {
            this.weeksOutDateCalculation = {
              start: moment(new Date()).add(res.priorityTypeShortcut.weeksOutStart, 'w'),
              end: moment(new Date()).add(res.priorityTypeShortcut.weeksOutEnd, 'w')
            };
          }
        }

        this.isLoading = false;
      }, error => this.isLoading = false);
    } else {
      this.isLoading = false;
    }
  }

  save() {
    if (!this.quote.neighborPermission.firstName || !this.quote.neighborPermission.lastName || !this.quote.neighborPermission.email) {
      this.messageService.add({
        severity: 'info',
        summary: 'Information Required',
        detail: 'Please enter your first name, last name, and email address.'
      });
    }

    this.neighborPermissionService.update(this.quote.neighborPermission).subscribe(res => {
      this.messageService.add({
        severity: 'success',
        summary: 'Save Successful',
        detail: 'Thank you! You should received a confirmation email for your records shortly.'
      });
       this.emailService.sendCustomerNeighborSignedEmail(this.quote, Base64.encode(this.quote.customerId.toString()), Base64.encode(this.quote.id.toString())).subscribe();
    }, error => {
      this.messageService.add({
        severity: 'error',
        summary: 'Unable to Save',
        detail: `Please verify the information is correct, including having a valid email address. If the problem persists, 
                please contact ${this.contactPhoneNumber} for assistance.`
      });
    });
  }

  onUpdatedNeighborSignature(signature: string) {
    this.quote.neighborPermission.neighborSignature = signature;
    this.quote.neighborPermission.signedDate = new Date();
  }

  sendEmailRequestToNeighbor() {
    if (confirm(`This will send an email to ${this.quote.neighborPermission.email} asking for permission to do the following activities ${this.quote.neighborPermission.activiesOnNeighborProperty}`)) {
      this.emailService.sendNeighborPermissionRequest(this.quote).subscribe();
    }
  }

}
