<div style="width: 70%; min-width: 300px; margin: auto">
  <div style="text-align: center;">
    <app-loading-swirly [isLoading]="isLoading"></app-loading-swirly>
  </div>
  
  <div *ngIf="!isLoading && !quote">
    Could not find the requested quote. Please contact your account manager or call {{contactPhoneNumber}} to assistance.
  </div>
  <div *ngIf="quote">
    <div class="bold">Work Address:</div>
    <p>
      {{quote.address.street}}, {{quote.address.city}}, {{quote.address.state.abbreviation}} {{quote.address.zip}}
    </p>

    <div class="bold">Projected Date of Work Completion:</div>
    <div *ngIf="quote.workOrderWellnessTasks?.length > 0 && quote.workOrderWorkTasks?.length == 0"><!-- Only has wellness tasks -->
      <p>
        Tree Wellness services will be performed at the most optimal time to apply treatment (based on the Growing Degree Day calendar). You will be notified 1-2 days prior to service(s) being performed. Contact us for a more specific timing of your services.
      </p>
    </div>
    <span *ngIf="!(quote.workOrderWellnessTasks?.length > 0 && quote.workOrderWorkTasks?.length == 0)"> <!-- Has work tasks (can also have wellness tasks) -->
      <div *ngIf="quote.priorityTypeShortcut?.weeksOutStart != undefined && quote.completionStatus != 'Work Orders Created'">
        <p>
          {{quote.priorityTypeShortcut.weeksOutStart}} to {{quote.priorityTypeShortcut.weeksOutEnd}} weeks out<br/>
        </p>
      </div>
      <div *ngIf="quote.priorityTypeShortcut?.weeksOutStart == undefined || quote.completionStatus == 'Work Orders Created'">
        <p *ngIf="quote.dueDateStart && quote.dueDateEnd">
          Between {{quote.dueDateStart | date: 'shortDate'}} and {{quote.dueDateEnd | date: 'shortDate'}}
        </p>
        <p *ngIf="quote.dueDateStart && !quote.dueDateEnd">
          After {{quote.dueDateStart | date: 'shortDate'}}
        </p>
        <p *ngIf="!quote.dueDateStart && quote.dueDateEnd">
          Before {{quote.dueDateEnd | date: 'shortDate'}}
        </p>   
      </div>
    </span>

    <div class="green-box" *ngIf="quote.neighborPermissionRequired">
      <div>
        <div class="bold">Neighbor's Permission <span style="text-decoration: underline;">Required:</span></div>
        <p>
          To complete tree work for {{quote.customer.fullName}}, our crew requires the ability to access the neighboring property at <span class="bold">{{quote.neighborPermission.address}}</span>.
        </p>
        
        <p class="bold">For the Owner of <span style="text-decoration: underline;">{{quote.neighborPermission.address}}:</span></p>
        <p>
          By signing below [1] I acknowledge I am the owner of the property of  <span class="bold">{{quote.neighborPermission.address}};</span> [2] I grant Russell Tree Experts ltd. (RTE) permission
          to access my property while they complete tree work for <span class="bold">{{quote.customer.fullName}};</span> [3] I acknowledge RTE warrants that all operations will 
          follow the ANSI Z 133.1 Industry safety standard; [4] For everyone's safety, I agree not to enter the work area during tree work operations.
        </p>
        <p>
          Description of activities to be performed: <span class="bold">{{quote.neighborPermission.activiesOnNeighborProperty}}</span>
          <br/>
          Please call {{quote.representative.fullName}} at {{quote.representative.phoneNumber | phoneNumber}} with any questions.
        </p>
      </div>
      <div class="p-grid ui-fluid">
        <div class="p-col-12 p-md-6">
          <div>First Name</div>
          <input 
            pInputText 
            name="neighborFirstName" 
            [(ngModel)]="quote.neighborPermission.firstName" 
            style="border-radius: 5px;" 
            [disabled]="quote.completionStatus === 'Work Orders Created'"
            [required]="quote.neighborPermissionRequired" />
        </div>
        <div class="p-col-12 p-md-6">
          <div>Last Name</div>
          <input 
            pInputText 
            name="neighborLastName" 
            [(ngModel)]="quote.neighborPermission.lastName" 
            style="border-radius: 5px;" 
            [disabled]="quote.completionStatus === 'Work Orders Created'"
            [required]="quote.neighborPermissionRequired" />
        </div>
        <div class="p-col-12 p-md-9">
          <div>Email Address</div>
          <input 
            pInputText 
            name="neighborLastName" 
            type="email" 
            [(ngModel)]="quote.neighborPermission.email" 
            style="border-radius: 5px;"
            [disabled]="quote.completionStatus === 'Work Orders Created'" 
            [required]="quote.neighborPermissionRequired" />
        </div>
        <div class="p-col-12 p-md-3">
          <div>Receive Marketing Emails? <i class="pi pi-info-circle" style="vertical-align: middle;" pTooltip="You will receive a confirmation email upon signing but if you want to be included on future RTE marketing emails, check the box below!"></i></div>
          <p-checkbox 
            binary="true" 
            name="showInactive" 
            [disabled]="quote.completionStatus === 'Work Orders Created'"
            [(ngModel)]="quote.neighborPermission.signUpForEmails">
          </p-checkbox>
        </div>
        <div class="p-col-12">
          <app-signature-area 
            [signature]="quote.neighborPermission.neighborSignature"
            [isAlwaysDisabled]="quote.completionStatus === 'Work Orders Created'"
            [name]="'Neighbor\'s'"
            (currentSignature)="onUpdatedNeighborSignature($event)"
          >
          </app-signature-area>
        </div>
      </div>
      <div style="text-align: right;">
        <button pButton label="Save" (click)="save()" style="border-radius: 5px;"></button>
      </div>
    </div>
  </div>
</div>