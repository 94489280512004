import { Component, OnInit, Input } from '@angular/core';
import { Customer,
  CustomerComment,
  CustomerGenSvc,
  CustomerNoteGenSvc,
  CustomerCommentStage,
  CustomerCommentStageGenSvc } from 'src/app/services_autogenerated/generated_services';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-customer-comments',
  templateUrl: './customer-comments.component.html',
  styleUrls: ['./customer-comments.component.css']
})
export class CustomerCommentsComponent implements OnInit {

  @Input() customerIdInput: number;
  @Input() claimNames: string[];
  @Input() defaultCategory: string;
  @Input() defaultWONumber: string;

  customer: Customer;
  cols = [
    { field: 'lastEditedDate', header: 'Last Edited', width: '10%' },
    { field: 'companyWide', header: 'Company Wide', width: '10%'},
    { field: 'customerCommentStage.commentStage', header: 'Stage', width: '10%' },
    { field: 'comment', header: 'Notes', width: '45%' },
    { field: 'quoteNumber', header: 'Quote/Work Order #', width: '15%' },
    { field: 'employee.fullName', header: 'Created By', width: '10%' },
  ];
  comment: CustomerComment;
  stages: CustomerCommentStage[];
  stageFilter: string;
  allComments: CustomerComment[];

  canEditOrDelete: boolean;

  constructor(
    private messageService: MessageService,
    private customerService: CustomerGenSvc,
    private authHelperService: AuthHelperService,
    private customerNoteService: CustomerNoteGenSvc,
    private customerNoteStageService: CustomerCommentStageGenSvc
  ) { }

  ngOnInit() {
    // Customer is reloaded here as oppossed to input to avoid allowing changes to the customer fields.
    // As there is two way binding in both the maintenence and comments page, passing the customer and then
    // updating within this class would allow for changes to be made to first name, etc.
    this.customerService.get(this.customerIdInput).subscribe((customer) => {
      this.customer = customer;
      this.allComments = cloneDeep(this.customer.customerComments);
      this.addComment();
    });

    this.customerNoteStageService.get().subscribe(stages => {
      this.stages = stages;

      if (this.comment && !this.comment.customerCommentStage && this.defaultCategory) {
        const stage = stages.find(s => s.commentStage === this.defaultCategory);

        if (stage) {
          this.comment.customerCommentStage = stage;
        }
      }
    });

    this.canEditOrDelete = this.authHelperService.getDecodedAccessToken().claimNames.includes('Full Control');
  }

  cancel() {
    this.comment = null;
  }

  editComment(comment: CustomerComment) {
    this.comment = cloneDeep(comment);
  }

  deleteComment(comment: CustomerComment) {
    if (confirm('Are you sure you want to remove this comment?')) {
      if (comment.id === this.comment.id) {
        this.addComment();
      }

      this.customerNoteService.delete(comment.id).subscribe(() => {
        this.allComments = this.allComments.filter(x => x.id !== comment.id);
        this.filterComments(this.stageFilter);
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting'
        });
      });
    }
  }

  addComment() {
    this.comment = new CustomerComment();
    this.comment.active = true;
    this.comment.customerId = this.customer.id;

    if (this.defaultWONumber) {
      this.comment.quoteNumber = this.defaultWONumber;
    }

    if (this.stages && !this.comment.customerCommentStage && this.defaultCategory) {
      const stage = this.stages.find(s => s.commentStage === this.defaultCategory);

      if (stage) {
        this.comment.customerCommentStage = stage;
      }
    }
  }

  saveComment(ngForm: NgForm) {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsDirty();
      ngForm.controls[key].markAsTouched();
    });
    if (ngForm.valid) {
      this.comment.employeeId = this.authHelperService.getDecodedAccessToken().employeeId; // updates last edited by

      if (this.comment.id) {
        this.customerNoteService.update(this.comment).subscribe(updatedComment => {
          const index = this.allComments.findIndex(comment => comment.id === updatedComment.id);
          this.allComments[index] = updatedComment;
          ngForm.resetForm();
          this.addComment();
          this.filterComments(this.stageFilter);
        });
      } else {
        this.customerNoteService.add(this.comment).subscribe(newComment => {
          this.allComments.push(newComment);
          ngForm.resetForm();
          this.addComment();
          this.filterComments(this.stageFilter);
        });
      }
    }
  }

  filterComments(stage?: string) {
    this.stageFilter = stage;
    if (this.stageFilter) {
      this.customer.customerComments = cloneDeep(this.allComments.filter(x =>
        x.customerCommentStage.commentStage === this.stageFilter));
    } else {
      this.customer.customerComments = cloneDeep(this.allComments);
    }
  }

}
