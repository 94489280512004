import { Routes } from '@angular/router';
import { WellnessDayViewComponent } from './components/wellness-day-view/wellness-day-view.component';
import { WellnessWeekViewComponent } from './components/wellness-week-view/wellness-week-view.component';
import { SchedulingTabsComponent } from './components/scheduling-tabs/scheduling-tabs.component';
import { EmployeeListComponent } from './components/employee-list/employee-list.component';
import { EmployeeMaintenanceComponent } from './components/employee-maintenance/employee-maintenance.component';
import { SkillListComponent } from './components/skill-list/skill-list.component';
import { SkillMaintenanceComponent } from './components/skill-maintenance/skill-maintenance.component';
import { EquipmentListComponent } from './components/equipment-list/equipment-list.component';
import { EquipmentMaintenanceComponent } from './components/equipment-maintenance/equipment-maintenance.component';
import { CrewCreationComponent } from './components/crew-creation/crew-creation.component';
import { ChemicalLocationMaintenanceComponent } from './components/chemical-location-maintenance/chemical-location-maintenance.component';
import { ChemicalMaintenanceComponent } from './components/chemical-maintenance/chemical-maintenance.component';
import { ChemicalMethodMaintenanceComponent } from './components/chemical-method-maintenance/chemical-method-maintenance.component';
import { PriorityMaintenanceComponent } from './components/priority-maintenance/priority-maintenance.component';
import { EquipmentTypeMaintenanceComponent } from './components/equipment-type-maintenance/equipment-type-maintenance.component';
import { WellnessWorkOrderMaintenanceComponent } from './components/wellness-work-order-maintenance/wellness-work-order-maintenance.component';
import { WellnessTaskTemplateListComponent } from './components/wellness-task-template-list/wellness-task-template-list.component';
import { WellnessTaskTemplateMaintenanceComponent } from './components/wellness-task-template-maintenance/wellness-task-template-maintenance.component';
import { WorkTaskTemplateListComponent } from './components/work-task-template-list/work-task-template-list.component';
import { WorkTaskTemplateMaintenanceComponent } from './components/work-task-template-maintenance/work-task-template-maintenance.component';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { WorkOrderListComponent } from './components/work-order-list/work-order-list.component';
import { SchedulingMultidayComponent } from './components/scheduling-multiday/scheduling-multiday.component';
import { LoginComponent } from './components/login/login.component';
import { MyScheduleComponent } from './components/my-schedule/my-schedule.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { AuthGuard } from './auth.guard';
import { PesticideRecordListComponent } from './components/pesticide-record-list/pesticide-record-list.component';
import { PesticideRecordMaintenanceComponent } from './components/pesticide-record-maintenance/pesticide-record-maintenance.component';
import { QuoteWorkOrderMaintenanceComponent } from './components/quote-work-order-maintenance/quote-work-order-maintenance.component';
import { WorkWorkOrderMaintenanceComponent } from './components/work-work-order-maintenance/work-work-order-maintenance.component';
import { SurveyMaintenanceComponent } from './components/survey-maintenance/survey-maintenance.component';
import { SurveyResponseComponent } from './components/survey-response/survey-response.component';
import { SurveyListComponent } from './components/survey-list/survey-list.component';
import { RequestedQuoteListComponent } from './components/requested-quote-list/requested-quote-list.component';
import { RequestedQuoteMaintenanceComponent } from './components/requested-quote-maintenance/requested-quote-maintenance.component';
import { ZipCodeMaintenanceComponent } from './components/regional-manager-scheduling/zip-code-maintenance/zip-code-maintenance.component';
import { ZipCodeAssignmentComponent } from './components/regional-manager-scheduling/zip-code-assignment/zip-code-assignment.component';
import { DayZipCodeAssignmentComponent } from './components/regional-manager-scheduling/day-zip-code-assignment/day-zip-code-assignment.component';
import { SchedulePageComponent } from './components/regional-manager-scheduling/schedule-page/schedule-page.component';
import { CustomerLandingPageComponent } from './components/customer-landing-page/customer-landing-page.component';
import { CustomerQuotePageComponent } from './components/customer-pages/customer-quote-page/customer-quote-page.component';
import { QuotePaymentComponent } from './components/quote-billing/quote-payment/quote-payment.component';
import { PasswordChangeComponent } from './components/password/password-change/password-change.component';
import { PasswordResetComponent } from './components/password/password-reset/password-reset.component';
import { CustomerFirmtimeSchedulingComponent } from './components/customer-firmtime-scheduling/customer-firmtime-scheduling.component';
import { InvoicePartialWorkComponent } from './components/invoice-partial-work/invoice-partial-work.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { PasswordResetGuard } from './password-reset.guard';
import { TreeTypeMaintenanceComponent } from './components/tree-type-maintenance/tree-type-maintenance.component';
import { CrewLogComponent } from './components/crew-log/crew-log.component';
import { PriorityBacklogMaintenanceComponent } from './components/priority-backlog-maintenance/priority-backlog-maintenance.component';
import { AnonymousCustomerQuotePageComponent } from './components/customer-pages/anonymous-customer-quote-page/anonymous-customer-quote-page.component';
import { OfficeManagerDashboardComponent } from './components/dashboards/office-manager-dashboard/office-manager-dashboard.component';
import { InvoiceFullPageComponent } from './components/invoice-full-page/invoice-full-page.component';
import { SystemSettingsComponent } from './components/system-settings/system-settings.component';
import { CommissionReportComponent } from './components/commission-report/commission-report.component';
import { ArReportComponent } from './components/ar-report/ar-report.component';
import { MessageToCustomerTemplateComponent } from './components/message-to-customer-template/message-to-customer-template.component';
import { ExportPageComponent } from './components/export-page/export-page.component';
var ɵ0 = { roles: ['Office Manager'] }, ɵ1 = { roles: ['Office Manager'] };
// Auth guard should go first, then password reset guard since canActivate works like the AND operator
// meaning that if auth guard fails, it doesn't go on to the next one. Which is what we want because if they are not
// logged in but they are supposed to be logged in, the password reset guard doesn't matter anymore
var routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'welcome', component: WelcomePageComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'scheduling', component: SchedulingTabsComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'scheduling/multiday/:type', component: SchedulingMultidayComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'scheduling/myschedule', component: MyScheduleComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'scheduling/:type', component: SchedulingTabsComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'wellnessTwoDayView', component: WellnessDayViewComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'wellnessWeekView', component: WellnessWeekViewComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'employeeList', component: EmployeeListComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'employeeMaintenance', component: EmployeeMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'employeeMaintenance/:id', component: EmployeeMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'equipmentList', component: EquipmentListComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'equipmentMaintenance', component: EquipmentMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'equipmentMaintenance/:id', component: EquipmentMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'skillList', component: SkillListComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'skillMaintenance', component: SkillMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'skillMaintenance/:id', component: SkillMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'wellnessTaskTemplateList', component: WellnessTaskTemplateListComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'wellnessTaskTemplateMaintenance', component: WellnessTaskTemplateMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'wellnessTaskTemplateMaintenance/:id', component: WellnessTaskTemplateMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'prioritiesMaintenance', component: PriorityMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'wellnessWorkOrderMaintenance', component: WellnessWorkOrderMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'wellnessWorkOrderMaintenance/:id', component: WellnessWorkOrderMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'quoteWorkOrderMaintenance', component: QuoteWorkOrderMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'quoteWorkOrderMaintenance/:id', component: QuoteWorkOrderMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'workWorkOrderMaintenance/:id', component: WorkWorkOrderMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'crewCreation', component: CrewCreationComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'chemicalMaintenance', component: ChemicalMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'chemicalMethodMaintenance', component: ChemicalMethodMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'chemicalLocationMaintenance', component: ChemicalLocationMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'equipmentTypeMaintenance', component: EquipmentTypeMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'workTaskTemplateList', component: WorkTaskTemplateListComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'workTaskTemplateMaintenance', component: WorkTaskTemplateMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'workTaskTemplateMaintenance/:id', component: WorkTaskTemplateMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'customerList', component: CustomerListComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'workOrderList', component: WorkOrderListComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'workOrderList/:type', component: WorkOrderListComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'pesticideApplicationRecordList', component: PesticideRecordListComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'pesticideApplicationRecordMaintenance', component: PesticideRecordMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'pesticideApplicationRecordMaintenance/:id', component: PesticideRecordMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'surveyMaintenance', component: SurveyMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'survey/:id', component: SurveyResponseComponent, canActivate: [PasswordResetGuard] },
    { path: 'survey/:custId/:quoteNum', component: SurveyResponseComponent },
    { path: 'surveyList', component: SurveyListComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'quoteList', component: RequestedQuoteListComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'quoteList/:type', component: RequestedQuoteListComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'requestedQuoteMaintenance', component: RequestedQuoteMaintenanceComponent, canActivate: [PasswordResetGuard] },
    { path: 'requestedQuoteMaintenance/:id', component: RequestedQuoteMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'quote', component: RequestedQuoteMaintenanceComponent, canActivate: [PasswordResetGuard] },
    { path: 'quote/:id', component: RequestedQuoteMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'admin/zipCodeMaintenance', component: ZipCodeMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'admin/zipCodeAssignment', component: ZipCodeAssignmentComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'assignZipCodeToDay/:id', component: DayZipCodeAssignmentComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'rmSchedulePage/:id', component: SchedulePageComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'customerLandingPage/:id/:tabIndex', component: CustomerLandingPageComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'customerLandingPage/:id', component: CustomerLandingPageComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'customerLandingPage', component: CustomerLandingPageComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'testStripeBilling', component: QuotePaymentComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    // { path: 'customerQuoteLandingPage', component: CustomerQuoteLandingPageComponent, canActivate: [PasswordResetGuard]},
    { path: 'customerQuotePage/:id', component: CustomerQuotePageComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'customerQuotePage', component: CustomerQuotePageComponent },
    { path: 'passwordChange', component: PasswordChangeComponent },
    { path: 'passwordReset', component: PasswordResetComponent },
    { path: 'ftSchedulePage/:id', component: CustomerFirmtimeSchedulingComponent },
    { path: 'invoicing', component: InvoicePartialWorkComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'invoicing/full', component: InvoiceFullPageComponent, canActivate: [AuthGuard, PasswordResetGuard], data: ɵ0 },
    { path: 'reporting', component: ReportingComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'reporting/commission', component: CommissionReportComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'reporting/ar', component: ArReportComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'reporting/export', component: ExportPageComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'treeTypeMaintenance', component: TreeTypeMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'crewLog', component: CrewLogComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'currentBacklogMaintenance', component: PriorityBacklogMaintenanceComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'customerQuoteAnonymous/:id', component: AnonymousCustomerQuotePageComponent },
    { path: 'dashboard/office', component: OfficeManagerDashboardComponent, canActivate: [AuthGuard, PasswordResetGuard], data: ɵ1 },
    { path: 'systemSettings', component: SystemSettingsComponent, canActivate: [AuthGuard, PasswordResetGuard] },
    { path: 'messageTemplates', component: MessageToCustomerTemplateComponent, canActivate: [AuthGuard, PasswordResetGuard] }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
