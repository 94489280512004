import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import * as moment from 'moment';
import {
  QuoteRequestGenSvc,
  EmployeeGenSvc,
  Employee,
  QuoteRequest,
  QuoteRequestScheduleUpdate,
  RegionalManagerScheduleEventGenSvc,
  RegionalManagerScheduleEvent,
  Address,
  QuoteRequestType,
  RegionalManagerTimeSlot,
} from '../../services_autogenerated/generated_services';
import { DecodedJwt } from '../../models/DecodedJwt';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthHelperService } from '../../services/auth-helper.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { ConfirmationService, MessageService } from 'primeng/api';
import { formatDate } from '@angular/common';
import { QuoteRequestFilterPipe } from '../../pipes/quote-request-filter.pipe';
import { LocationColorService } from '../../services/location-color.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Observable, forkJoin } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-customer-firmtime-scheduling',
  templateUrl: './customer-firmtime-scheduling.component.html',
  styleUrls: ['./customer-firmtime-scheduling.component.css']
})
export class CustomerFirmtimeSchedulingComponent implements OnInit {
  token: DecodedJwt;
  employee: Employee;
  events = [];
  options = {
    defaultEventMinutes: 60
  };
  // Count is used for double click functionality, since dblClick cannot be used on mobile devices
  count = 0;

  canAddOrEdit: boolean;
  canAddFirmtime: boolean;
  isCustomer: boolean;

  displayNewEventModal: boolean;
  newSelectEvent: any;

  rmScheduleEvents: RegionalManagerScheduleEvent[] = [];
  anytimeQuoteRequests: QuoteRequest[] = [];

  searchTerm: string;

  scheduledQuoteRequests: QuoteRequest[];
  quoteId: number;
  confirmPageDisplay = false;
  timeSlotsForZipCode: RegionalManagerTimeSlot[];
  displayStartDate = moment().add(2, 'day').toDate();
  displayEndDate = moment().add(8, 'days').toDate();
  displayAvailable: ScheduleDay[] = [];
  selectedTime: Date;
  quoteRequest: QuoteRequest;

  rmId: number;

  @Input() inputId: number;

  @ViewChild('calendarMobile') calendarMobile;
  @ViewChild('calendarNonMobile') calendarNonMobile;
  @ViewChild('external') external: ElementRef;

  constructor(private quoteRequestService: QuoteRequestGenSvc,
    private route: ActivatedRoute,
    private router: Router,
    private authHelperService: AuthHelperService,
    private employeeService: EmployeeGenSvc,
    private rmScheduleEventService: RegionalManagerScheduleEventGenSvc,
    private confirmationService: ConfirmationService,
    private locationService: LocationColorService,
    private messageService: MessageService,
    public customerService: CustomerService,
    private configService: AppConfigService) { }

  ngOnInit() {
    this.token = this.authHelperService.getDecodedAccessToken();
    this.isCustomer = this.token ? this.token.userType === 'customer' : true;

    const quoteId = localStorage.getItem('quoteRequestId');
    this.quoteId = +quoteId;
    this.quoteRequestService.get(+quoteId).subscribe(quoteRequest => {
      this.quoteRequest = quoteRequest;
    });

    let id;
    if (this.inputId) {
      id = this.inputId;
    } else {
      id = +this.route.snapshot.paramMap.get('id');
    }
    this.employeeService.get(id).subscribe(emp => {
      this.employee = emp;
      this.getAvailableTimeSlots();
      this.dateRangeChange();
    });
  }

  eventSelect(time) {
    this.selectedTime = time;
    this.quoteRequest.eventStart = this.selectedTime;
    if (this.isCustomer) {
      // Customer scheduling a firmtime
      // Ignore the end time, the event will only be an hour for the customer appointment
      this.confirmScheduleDate(moment(time));
    } else if (+localStorage.getItem('quoteRequestId')) {
      // this is if an employee is scheduling a firmtime for a customer
      this.confirmScheduleDate(moment(time));
    }
  }

  private confirmScheduleDate(date: moment.Moment) {
    this.confirmPageDisplay = true;
  }

  onPaymentSuccess() {
    const eventEnd = moment(this.selectedTime).clone();
    eventEnd.add(1, 'h');
    if (+localStorage.getItem('quoteRequestId')) {
      const requestUpdate = new QuoteRequestScheduleUpdate({
        regionalManagerId: this.employee.id,
        eventStart: this.selectedTime,
        eventEnd: eventEnd.toDate(),
        quoteRequestId: +localStorage.getItem('quoteRequestId')
      });

      this.quoteRequestService.updateStartOrEnd(requestUpdate, requestUpdate.quoteRequestId.toString()).subscribe(qr => {
        localStorage.removeItem('zipCode');
        localStorage.removeItem('quoteRequestId');
        const confirmLink = this.configService.appConfig.quoteRequestConfirmationLink;
        if (this.isCustomer) {
          this.route.queryParams
          .subscribe(params => {
            params.embedded === 'true' ?
              window.top.location.href = confirmLink :
              window.location.assign(confirmLink);
          });
        } else {
          this.authHelperService.redirectToHome();
        }
      });
    }
  }

  navigateToQuoteRequest(id) {
    if (confirm('Do you want to navigate to the Request for Quote maintenance page?')) {
      this.router.navigateByUrl('requestedQuoteMaintenance/' + id);
    }
  }

  getAvailableTimeSlots() {
    const zip = localStorage.getItem('zipCode');
    this.timeSlotsForZipCode = this.employee.regionalManagerTimeSlots.
    filter(timeSlot => timeSlot.regionalManagerTimeSlotZipCodes.some(zc => zc.zipCode.code === zip));
  }

  getScheduledEvents(startDate: Date, endDate: Date) {
    this.events = [];
    // Id as a parameter means someone is navigating to an RM's schedule
    // Otherwise get the id from the token of the logged in user.
    let id ;
    if (this.inputId) {
      id = this.inputId;
    } else {
      id = +this.route.snapshot.paramMap.get('id') ? +this.route.snapshot.paramMap.get('id') : this.token.id;
    }

    if (id) {
      this.rmId = id;
      // Get the quote requests that have been scheduled for the employee
      const getEvents1 = this.quoteRequestService.getAllRequestsInDateRangeForRM(id, startDate, endDate);

      // Get the non-quote request events that have been scheduled for the employee, ie the "blocks"
      const getEvents2 = this.rmScheduleEventService.getAllEventsInDateRangeForRM(id, startDate, endDate);

      forkJoin(getEvents1, getEvents2).subscribe(results => {
        this.scheduledQuoteRequests = results[0];
        this.rmScheduleEvents = results[1];
        this.calculateDisplayAvailable();
      });
    }
  }

  clickForMore() {
    this.displayEndDate = moment(this.displayEndDate).add(7, 'd').toDate();
    // Get the quote requests that have been scheduled for the employee
    const getEvents1 = this.quoteRequestService.getAllRequestsInDateRangeForRM(this.rmId, this.displayStartDate, this.displayEndDate);

    // Get the non-quote request events that have been scheduled for the employee, ie the "blocks"
    const getEvents2 = this.rmScheduleEventService.getAllEventsInDateRangeForRM(this.rmId, this.displayStartDate, this.displayEndDate);

    forkJoin(getEvents1, getEvents2).subscribe(results => {
      this.scheduledQuoteRequests = results[0];
      this.rmScheduleEvents = results[1];
      this.calculateDisplayAvailable();
    });
  }

  calculateDisplayAvailable() {
    this.displayAvailable = [];
    const days = moment(this.displayEndDate).diff(this.displayStartDate, 'days') + 1;
    // No filter cause time slots span all days
    const timeSlots = this.timeSlotsForZipCode; // .filter(this.isTimeSlotInDateRange);
    // Loop through days of week
    for (let x = 0; x < days; x++) {
      const date = moment(this.displayStartDate).clone();
      date.add(x, 'days');
      // Loop through adding hour time slots based on RM schedule
      const times = [];
      // Filter time slot by date's day of week
      const timeSlotsForDate = this.timeSlotsForZipCode.filter(timeSlot => timeSlot.dayOfTheWeek === date.day());
      timeSlotsForDate.forEach(slot => {
        // Only use RM schedule slots matching date
        // Set timeIndex equal to date at slot start time
        let timeIndex = moment(date.format('YYYY-MM-DD ' + moment(slot.timeBegin).format('HH:mm'))).toDate();
        // Set timeEnd equal to date at slot end time
        const timeEnd = moment(date.format('YYYY-MM-DD ' + moment(slot.timeEnd).format('HH:mm')));
        const hour = timeIndex.getHours();
        // Start time must be on the hour
        if (timeIndex.getMinutes() !== 0) {
          timeIndex.setHours(hour + 1);
        }
        // Add time for each hour in time slot
        while (moment(timeIndex).isBefore(timeEnd) && moment(timeIndex).isBefore(moment(this.displayEndDate))) {
          // Only 24hrs from current time that aren't already
          const dayAfterTomorrow = moment().add(2, 'day');
          if ((moment(timeIndex).isAfter(dayAfterTomorrow, 'hour'))
              && this.findDateIndex(times, timeIndex) < 0) {
            times.push(timeIndex);
          }
          const time = moment(timeIndex);
          time.add(1, 'hours');
          timeIndex = time.toDate();
        }
      });

      if (times.length !== 0) {
        this.displayAvailable.push(
          {
            day: date.toDate(),
            times: times.sort((a, b) => a - b)
          }
        );
      }
    }

    // Remove already taken slots
    const quoteRequestInRange = this.scheduledQuoteRequests.filter(this.isEventInDateRange);
    const rmEventsInRange = this.rmScheduleEvents.filter(this.isEventInDateRange);
    const eventsInRange = quoteRequestInRange.concat(rmEventsInRange);

    eventsInRange.forEach((event) => {
      // Find days with conflicting times
      const conflictingDays = this.displayAvailable.filter(available => moment(available.day).isBetween(moment(event.eventStart), moment(event.eventEnd), 'day', '[]'));
      conflictingDays.forEach((conflictingDay) => {
        if (conflictingDay.times) {
          // Remove conflicting times from days
          conflictingDay.times = conflictingDay.times.filter(time => !moment(time).isBetween(moment(event.eventStart), moment(event.eventEnd), null, '[)'));
        }
      });
    });
  }

  findDateIndex(dates: Date[], dateToFind: Date): number {
    return dates.findIndex(function(date) {
      return date.valueOf() === dateToFind.valueOf();
    });
  }

  isEventInDateRange = (event: any) => {
    return moment(event.eventStart).isBetween(moment(this.displayStartDate), moment(this.displayEndDate), null, '()')
    || moment(event.eventEnd).isBetween(moment(this.displayStartDate), moment(this.displayEndDate), null, '()')
    || (moment(event.eventStart).isSameOrBefore(moment(this.displayStartDate)) && moment(event.eventEnd).isSameOrAfter(moment(this.displayEndDate)));
  }

  isTimeSlotInDateRange = (timeSlot: RegionalManagerTimeSlot) => {
    return moment(timeSlot.timeBegin).isBetween(moment(this.displayStartDate), moment(this.displayEndDate), null, '()')
    || moment(timeSlot.timeEnd).isBetween(moment(this.displayStartDate), moment(this.displayEndDate), null, '()')
    || (moment(timeSlot.timeBegin).isSameOrBefore(moment(this.displayStartDate)) && moment(timeSlot.timeEnd).isSameOrAfter(moment(this.displayEndDate)));
  }

  // openPaymentModal(quoteId: number) {
  //   this.confirmPageDisplay = true;
  // }

  // closeQuoteBillingModal() {
  //   this.confirmPageDisplay = false;
  // }

  setStartDate(newStartDate: Date) {
    this.displayStartDate = newStartDate;
    const newEndDate = moment(newStartDate).clone();
    newEndDate.add(6, 'days');
    this.displayEndDate = newEndDate.toDate();
    this.displayEndDate.setHours(23);
    this.dateRangeChange();
  }

  pageDateRange(pageIncrement) {
    const daysToAdd = pageIncrement * 7;
    const newStartDate = moment(this.displayStartDate);
    const newEndDate = moment(this.displayEndDate);
    newStartDate.add(daysToAdd, 'days');
    newEndDate.add(daysToAdd, 'days');
    this.displayStartDate = newStartDate.toDate();
    this.displayEndDate = newEndDate.toDate();
    this.displayEndDate.setHours(23);
    this.dateRangeChange();
  }

  dateRangeChange() {
    const tomorrow = moment().add(1, 'day');
    if (moment(this.displayStartDate).isBefore(tomorrow)) {
      this.displayStartDate = moment().add(1, 'day').toDate();
      this.displayEndDate = moment().add(7, 'days').toDate();
      this.displayEndDate.setHours(23);
    }
    this.getScheduledEvents(this.displayStartDate, this.displayEndDate);
  }

  openCalendar() {
    this.calendarNonMobile.showOverlay(this.calendarNonMobile.inputfieldViewChild.nativeElement);
    this.calendarMobile.showOverlay(this.calendarMobile.inputfieldViewChild.nativeElement);
  }
}

class ScheduleDay {
    day: Date;
    times: Date[];
}
