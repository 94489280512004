import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { CustomerContact, EmailAddress } from 'src/app/services_autogenerated/generated_services';
import { ControlContainer, NgForm } from '@angular/forms';
import { DecodedJwt } from 'src/app/models/DecodedJwt';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { UserTypes } from 'src/app/models/enums/UserTypes';
import { Guid } from 'src/app/models/guid';
import { CustomerTypes } from 'src/app/models/enums/customerTypes';

@Component({
  selector: 'app-customer-contact',
  templateUrl: './customer-contact.component.html',
  styleUrls: ['./customer-contact.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CustomerContactComponent implements OnInit {

  @Input() canEdit: boolean;
  @Input() customerContact: CustomerContact;
  @Input() customerTypeId: number;
  @Input() smallerHeaders: boolean;

  @Output() deleted = new EventEmitter<CustomerContact>();

  @ViewChild('custContactForm') custContactForm: NgForm;

  customerTypesEnum = CustomerTypes;
  displayNotesDialog = false;
  editedNote: string;
  isCustomer = false;
  randomGuid = Guid.newGuid();
  token: DecodedJwt;
  public UserTypes = UserTypes;

  constructor(
    private authHelperService: AuthHelperService
  ) { }

  ngOnInit() {
    this.token = this.authHelperService.getDecodedAccessToken();
    this.isCustomer = !this.token || this.token.userType === 'customer'; // Hides certain things from the customer
  }

  delete() {
    this.deleted.emit(this.customerContact);
  }

  openNotes() {
    this.editedNote = this.customerContact.notes;
    this.displayNotesDialog = true;
  }

  closedNotesDialog(saveChanges: boolean) {
    if (saveChanges) {
      this.customerContact.notes = this.editedNote;
    }
    this.displayNotesDialog = false;
  }

  emailChanged(email: EmailAddress) {
    email.email = email.email.trim();
  }

  markAllAsDirty() {
    Object.keys(this.custContactForm.controls).forEach(key => {
      this.custContactForm.controls[key].markAsTouched();
      this.custContactForm.controls[key].markAsDirty();
    });
  }
}
