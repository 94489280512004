import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import {
  Skill,
  Employee,
  Address,
  Role,
  EmployeeSkill,
  EmployeeRole,
  StateGenSvc,
  State,
  EmailAddress
} from '../../services_autogenerated/generated_services';
import { RoleGenSvc, SkillGenSvc, EmployeeGenSvc } from '../../services_autogenerated/generated_services';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { BlobManagerComponent } from '../blob-manager/blob-manager.component';
import { Guid } from 'src/app/models/guid';
import { EmployeeSkillUploadComponent } from '../employee-skill-upload/employee-skill-upload.component';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthHelperService } from 'src/app/services/auth-helper.service';
import { DecodedJwt } from 'src/app/models/DecodedJwt';

@Component({
  selector: 'app-employee-maintenance',
  templateUrl: './employee-maintenance.component.html',
  styleUrls: ['./employee-maintenance.component.css']
})
export class EmployeeMaintenanceComponent implements OnInit {
  canEdit: boolean;
  cols: any[];
  disableDelete = false;
  employee: Employee;
  employeeRoleOptions: EmployeeRole[];
  employeeIsRM: boolean;
  roles: Role[];
  saving = false;
  selectedRoles: Role[];
  selectedState: State;
  skills: Skill[];
  states: State[];

  token: DecodedJwt;

  // @ViewChildren(BlobManagerComponent) blobManagers: QueryList<BlobManagerComponent>;
  @ViewChildren(EmployeeSkillUploadComponent) employeeSkillUploadComponents: QueryList<EmployeeSkillUploadComponent>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private roleService: RoleGenSvc,
    private skillService: SkillGenSvc,
    private employeeService: EmployeeGenSvc,
    private stateService: StateGenSvc,
    private messageService: MessageService,
    private authHelper: AuthHelperService

  ) {
    this.cols = [
      { field: 'shortName', header: 'Skill/Certification', width: '30%' },
      { field: 'startDate', header: 'Start Date', type: Date, width: '15%' },
      { field: 'endDate', header: 'End Date', type: Date, width: '15%' },
      { header: 'File', width: '30%' },
      { header: 'Delete', width: '10%' }
    ];
  }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.token = this.authHelper.getDecodedAccessToken();

    this.canEdit = this.token.claimNames.includes('View/Edit Employee Maint.');

    this.skillService.getAll().subscribe(skills => {
      this.skills = skills;
    });

    this.stateService.getAll().subscribe(states => this.states = states);

    if (id) {
      // tslint:disable-next-line: deprecation
      Observable.forkJoin(this.employeeService.get(id), this.roleService.getAll())
        .subscribe(([employee, roles]) => {
          this.roles = roles;
          this.employee = employee;
          this.afterEmployeeAndRolesReturn(this.employee, this.roles);
          this.disableDelete = !this.token.claimNames.includes('View/Edit Employee Maint.');
          this.selectedState = employee.address.state;
        });
    } else {
      this.disableDelete = true;
      const emp = new Employee();
      emp.currentlyEmployed = true;
      emp.employeeSkills = [];
      emp.employeeRoles = [];
      emp.address = new Address();
      emp.emailAddress = new EmailAddress();
      this.employee = emp;
      this.roleService.getAll().subscribe(roles => {
        this.roles = roles;
        this.afterEmployeeAndRolesReturn(this.employee, this.roles);
      });

      this.stateService.getAll().subscribe(states => {
        this.states = states;
        this.selectedState = states.find(state => state.name === 'Ohio');
        this.employee.address.state = this.selectedState;
      });
    }
  }

  afterEmployeeAndRolesReturn(employee: Employee, roles: Role[]) {
    this.employeeRoleOptions = employee.employeeRoles; // start with existing roles

    // add other options from roles they could pick from
    const otherRoles = roles.filter(r => !employee.employeeRoles.some(er => er.roleId === r.id));

    this.employeeRoleOptions = this.employeeRoleOptions.concat(otherRoles.map(r => {
      const newER = new EmployeeRole();
      newER.roleId = r.id;
      newER.role = r;
      newER.employeeId = employee.id; // may be null if new employee
      return newER;
    }));

    this.updateEmployeIsRM();
  }

  updateEmployeIsRM() {
    this.employeeIsRM = this.employee.employeeRoles.some(er => er.role.name.includes('Regional Manager'));
  }

  save(ngForm: NgForm): void {
    Object.keys(ngForm.controls).forEach(key => {
      ngForm.controls[key].markAsTouched();
      ngForm.controls[key].markAsDirty();
    });
    if (ngForm.valid) {
      this.saving = true;
      if (this.employee.id) {
        this.employeeService.update(this.employee, this.employee.id.toString())
          .subscribe(emp => {
            if (this.employee.employeeSkills && this.employee.employeeSkills.length > 0) {
              this.saveBlobs(emp);
            } else {
              this.doneSaving();
            }
          }, error => {
            this.showErrorMessage(error.status);
            this.doneSaving();
          });
      } else {
        this.employeeService.add(this.employee)
          .subscribe(emp => {
            if (this.employee.employeeSkills && this.employee.employeeSkills.length > 0) {
              this.saveBlobs(emp);
            } else {
              this.doneSaving();
            }
          }, error => {
            this.showErrorMessage(error.status);
            this.doneSaving();
          });
      }
    }
  }

  saveBlobs(savedEmp: Employee) {
    const blobObservables = [];
    this.employeeSkillUploadComponents.forEach(uploadComponent => {
      uploadComponent.blobManagerComponent.parentRecordId = savedEmp.employeeSkills
        .find(empSkill => (empSkill.id && empSkill.id === uploadComponent.employeeSkill.id)
          || (empSkill.tempSaveGUID && empSkill.tempSaveGUID === uploadComponent.employeeSkill.tempSaveGUID)).id;

      blobObservables.push(uploadComponent.blobManagerComponent.save());
    });

    Observable.forkJoin(blobObservables)
      .pipe(catchError((error) => {
        return of(null);
      }))
      .subscribe((res) => {
        if (res) {
          this.doneSaving();
        } else {
          alert('Failed to upload Photos/Documents. Please try again later.');
        }
      });
  }

  private showErrorMessage(status) {
    if (status === 409) {
      this.messageService.add({
        severity: 'error',
        summary: 'Email Already In Use',
        detail: 'The entered email address is already in use, please select another one.'
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Saving',
        detail: 'Could not save the employee, please check that all fields are valid and retry.'
      });
    }
  }

  private doneSaving = () => {
    this.saving = false;

    if (this.token.claimNames.includes('View/Edit Employee Maint.')) {
      this.router.navigateByUrl('/employeeList');
    } else {
      this.authHelper.redirectToHome();
    }
  }

  cancel() {
    if (this.token.claimNames.includes('View/Edit Employee Maint.')) {
      this.router.navigateByUrl('/employeeList');
    } else {
      this.authHelper.redirectToHome();
    }
  }

  delete() {
    if (confirm('Are you sure you want to permanently delete this user? This cannot be undone.')) {
      this.employeeService.delete(this.employee.id).subscribe(() => {
        this.doneSaving();
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error Deleting',
          detail: 'Could not delete the employee, please check that the employee exists and retry.'
        });
      });
    }
  }

  addNewSkill() {
    const empSkill = new EmployeeSkill();
    empSkill.tempSaveGUID = Guid.newGuid();
    empSkill.employeeId = this.employee.id;
    this.employee.employeeSkills.push(empSkill);
  }

  removeSkill(rowIndex) {
    this.employee.employeeSkills.splice(rowIndex, 1);
  }

  onSkillChange(es: EmployeeSkill, skill: Skill) {
    es.skillId = skill.id;
  }

  onStateSelect(state: State) {
    this.employee.address.stateId = state.id;
    this.employee.address.state = state;
  }

  onSkillFromDateChange(form: NgForm, rowIndex: number, es: EmployeeSkill) {
    // auto-populate the To Date, if it makes sense
    if (es && es.skill && es.skill.length && !es.toDate) {
      es.toDate = moment(es.fromDate).add(es.skill.length, 'years').toDate();
    }

    // if the from date changes, validate the To Date
    setTimeout(() => {
      form.controls['toDate' + rowIndex].updateValueAndValidity();
    }, 200);
  }

  navigateToRMSchedulingPage() {
    this.router.navigateByUrl('rmSchedulePage/' + this.employee.id);
  }
}
