<br>
<div class="ui-g">
    <div class="ui-g-12 ui-lg-12">
      <h4>Priority Level Information</h4>
    </div>
</div>
<br>
<form id="priorityLevelForm" #priorityLevelForm="ngForm">
  <div class="ui-g">
      <div class="ui-g-12 ui-lg-6">
          <h6>Priority Level Name</h6>
          <input pInputText #priorityNameInput class="inputTextBox" name="status" [(ngModel)]="priority.status" required/>
      </div>
      <div class="ui-g-6 ui-lg-2">
          <h6>Color</h6>
          <input pInputText readonly class="inputTextBox" name="color" [(colorPicker)]="color" [cpOutputFormat]="'hex'" [style.background]="color" required/>
      </div>
      <div class="ui-g-2">
          <h6>Order</h6>
          <input type="number" pInputText name="order" class="inputTextBox" [(ngModel)]="priority.order" required/>
      </div>
  </div>
  <div class="ui-g">
      <div class="ui-g-12 columnPadding">
        <div *ngIf="priorityLevelForm.invalid && priorityLevelForm.submitted" class="alert alert-danger" role="alert">
          There are errors on this page. Please correct the errors and resubmit.
        </div>
      </div>
      <div class="ui-g-12 buttonStyle" style="text-align: right">
          <button 
            pButton
            type="submit"
            (click)="savePriority(priorityLevelForm)"
            [disabled]="saving"
            class="ui-button-raised ui-button-success buttonStyle"
            label="Save">
          </button>
          <button style="margin: 8px;"
            pButton 
            type="button" 
            class="ui-button-raised ui-button-success buttonStyle" 
            (click)="clear(priorityLevelForm);" 
            label="Clear">
          </button>
      </div>
  </div>
</form>
<br>
<br>
<p-table [columns]="cols" [value]="priorities" [paginator]="true" [rows]="30" [responsive]="true">
  <ng-template pTemplate="header" let-columns>
      <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
              {{col.header}}
              <p-sortIcon [field]="col.field" *ngIf="col.header != ''" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
          </th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-priority
    let-columns="columns"
    let-rowIndex="rowIndex">
    <tr>
      <td><span class="ui-column-title">{{columns[0].header}}</span>{{priority.status}}</td>
      <td [ngStyle]="{'background-color': priority.backgroundColor}"><span class="ui-column-title">{{columns[1].header}}</span></td>
      <td><span class="ui-column-title">{{columns[2].header}}</span>{{priority.order}}</td>
      <td>
        <button 
          pButton
          type="button"
          icon="pi pi-pencil"
          (click)="editPriority(priority)"
          class="ui-button-secondary blackBorder editButtonMargin">
        </button>
        <!-- GO BACKS ARE ID 1 AND ARE PROTECTED FROM DELETE -->
        <button 
          pButton
          type="button"
          icon="pi pi-trash"
          (click)="deletePriority(priority)"
          [disabled]="priority.id == 1"
          class="ui-button-danger">
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
<br>

